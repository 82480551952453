var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isLoadingPrices
        ? _c(
            "v-sheet",
            {
              staticClass: "mb-2 py-n6 mt-2",
              attrs: {
                rounded: "",
                outlined: ""
              }
            },
            [
              _c("v-skeleton-loader", {
                attrs: {
                  type: "article"
                }
              })
            ],
            1
          )
        : _vm.availableProducts.length
        ? _c(
            "v-sheet",
            {
              staticClass: "mb-2 py-n6 mt-2",
              attrs: {
                rounded: "",
                outlined: ""
              }
            },
            [
              _c(
                "v-form",
                {
                  ref: "quoteForm",
                  staticClass: "ma-4",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c("v-card-text", [
                        _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgQuoteForm.customerInfo"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c(
                        "v-row",
                        [
                          _c("v-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              rules: _vm.requiredRule,
                              label: _vm.$t(
                                "components.thg.ThgQuoteForm.firstName"
                              ),
                              required: "",
                              outlined: "",
                              "data-test-form-firstName": ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "prepend",
                                fn: function fn() {
                                  return [
                                    _c(
                                      "v-tooltip",
                                      {
                                        attrs: {
                                          bottom: ""
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "activator",
                                            fn: function fn(_ref) {
                                              var on = _ref.on
                                              return [
                                                _c("v-icon", _vm._g({}, on), [
                                                  _vm._v(
                                                    " mdi-help-circle-outline "
                                                  )
                                                ])
                                              ]
                                            }
                                          }
                                        ])
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.thg.ThgQuoteForm.tooltip.firstName"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ]),
                            model: {
                              value: _vm.thgQuote.createUserDto.firstName,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.thgQuote.createUserDto,
                                  "firstName",
                                  $$v
                                )
                              },
                              expression: "thgQuote.createUserDto.firstName"
                            }
                          }),
                          _c("v-text-field", {
                            staticClass: "px-3",
                            attrs: {
                              rules: _vm.requiredRule,
                              label: _vm.$t(
                                "components.thg.ThgQuoteForm.lastName"
                              ),
                              required: "",
                              outlined: "",
                              "data-test-form-lastName": ""
                            },
                            model: {
                              value: _vm.thgQuote.createUserDto.lastName,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.thgQuote.createUserDto,
                                  "lastName",
                                  $$v
                                )
                              },
                              expression: "thgQuote.createUserDto.lastName"
                            }
                          })
                        ],
                        1
                      ),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.emailRule,
                          label: _vm.$t("components.thg.ThgQuoteForm.userName"),
                          required: "",
                          outlined: "",
                          type: "email",
                          "data-test-form-userName": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function fn() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function fn(_ref2) {
                                          var on = _ref2.on
                                          return [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v(
                                                " mdi-help-circle-outline "
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgQuoteForm.tooltip.userName"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.thgQuote.createUserDto.userName,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.thgQuote.createUserDto,
                              "userName",
                              $$v
                            )
                          },
                          expression: "thgQuote.createUserDto.userName"
                        }
                      }),
                      _c("v-card-text", [
                        _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("components.thg.ThgQuoteForm.quoteInfo")
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.numberplateRule,
                          label: _vm.$t(
                            "components.thg.ThgQuoteForm.numberplate"
                          ),
                          required: "",
                          outlined: "",
                          "data-test-form-numberplate": ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prepend",
                            fn: function fn() {
                              return [
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: {
                                      bottom: ""
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function fn(_ref3) {
                                          var on = _ref3.on
                                          return [
                                            _c("v-icon", _vm._g({}, on), [
                                              _vm._v(
                                                " mdi-help-circle-outline "
                                              )
                                            ])
                                          ]
                                        }
                                      }
                                    ])
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgQuoteForm.tooltip.numberplate"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.thgQuote.numberplate,
                          callback: function callback($$v) {
                            _vm.$set(_vm.thgQuote, "numberplate", $$v)
                          },
                          expression: "thgQuote.numberplate"
                        }
                      }),
                      _c("thg-registration-upload-card"),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "components.thg.ThgQuoteForm.addRegistration"
                          ),
                          "data-test-add-registration-checkbox": ""
                        },
                        model: {
                          value: _vm.isUserAddRegistration,
                          callback: function callback($$v) {
                            _vm.isUserAddRegistration = $$v
                          },
                          expression: "isUserAddRegistration"
                        }
                      }),
                      _vm.isUserAddRegistration
                        ? _c("v-card-text", [
                            _c("h3", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgQuoteForm.registrationInfo"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          ])
                        : _vm._e(),
                      _vm.isUserAddRegistration
                        ? _c("thg-registration-form", {
                            attrs: {
                              registration: _vm.registration
                            },
                            on: {
                              valid: _vm.updateRegistrationValid
                            }
                          })
                        : _vm._e(),
                      _c("v-card-text", [
                        _c("h3", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgQuoteForm.submissionInfo"
                                )
                              ) +
                              " "
                          )
                        ])
                      ]),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            rules: _vm.requiredRule
                          },
                          model: {
                            value: _vm.thgQuote.years,
                            callback: function callback($$v) {
                              _vm.$set(_vm.thgQuote, "years", $$v)
                            },
                            expression: "thgQuote.years"
                          }
                        },
                        _vm._l(_vm.availableProducts, function(n, key) {
                          return _c("v-radio", {
                            key: key,
                            attrs: {
                              label: _vm.transformYearsToString(n.years),
                              value: n.years
                            }
                          })
                        }),
                        1
                      ),
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "components.thg.ThgQuoteForm.confirmation"
                          ),
                          rules: _vm.requiredRule,
                          "data-test-confirmed-checkbox": ""
                        },
                        model: {
                          value: _vm.thgQuote.isConfirmed,
                          callback: function callback($$v) {
                            _vm.$set(_vm.thgQuote, "isConfirmed", $$v)
                          },
                          expression: "thgQuote.isConfirmed"
                        }
                      }),
                      _c("v-checkbox", {
                        ref: "privacy",
                        attrs: {
                          label: _vm.$t(
                            "components.thg.ThgQuoteForm.confirmationPrivacy"
                          ),
                          rules: _vm.requiredRule,
                          "data-test-privacy-checkbox": ""
                        },
                        model: {
                          value: _vm.isTermsAccepted,
                          callback: function callback($$v) {
                            _vm.isTermsAccepted = $$v
                          },
                          expression: "isTermsAccepted"
                        }
                      }),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "success",
                            disabled: _vm.disabled,
                            "data-test-form-submit": "",
                            loading: _vm.loading,
                            "x-large": ""
                          },
                          on: {
                            click: _vm.save
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("components.thg.ThgQuoteForm.submit")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-sheet",
            {
              staticClass: "mb-2 py-n6 mt-2",
              attrs: {
                rounded: "",
                outlined: ""
              }
            },
            [
              _c(
                "v-list",
                {
                  attrs: {
                    "three-line": ""
                  }
                },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(
                              _vm._s(_vm.$t("report.thgProducts.noItemsTitle"))
                            )
                          ]),
                          _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("report.thgProducts.noItemsSubtitle")
                              ) + " "
                            ),
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "mailto:info@mintfuture.de"
                                }
                              },
                              [_vm._v("info@mintfuture.de")]
                            )
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 mb-4",
                  attrs: {
                    color: "info",
                    small: "",
                    loading: _vm.isLoadingPrices
                  },
                  on: {
                    click: _vm.fetchPrices
                  }
                },
                [_vm._v(_vm._s(_vm.$t("report.thgProducts.noItemsBtn")))]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.resize,
          expression: "resize"
        }
      ],
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "div",
        {
          style: "height:".concat(_vm.height)
        },
        [_c("thg-cropped-image")],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ma-6",
          attrs: {
            align: "center",
            justify: "center",
            md: "9",
            cols: "12"
          }
        },
        [_vm._t("navrow"), _c("thg-cropped-image-styling-options")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
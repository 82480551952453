var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        "hide-overlay": "",
        transition: "dialog-bottom-transition",
        scrollable: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attr = _ref.attr
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.initialize
                      }
                    },
                    "v-btn",
                    attr,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t("components.thg.ThgBatchUpdateItemsDialog.title")
                    )
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.isDisabled,
                    loading: _vm.loading
                  },
                  on: {
                    click: _vm.addItemsToBatch
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.thg.ThgBatchUpdateItemsDialog.add")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            [
              _c("p", {
                staticClass: "mt-4"
              }),
              _c(
                "v-row",
                {
                  attrs: {
                    dense: ""
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        md: "6"
                      }
                    },
                    [
                      _c("p", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.ThgBatchUpdateItemsDialog.subTitle"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("v-text-field", {
                        attrs: {
                          type: "text",
                          label: _vm.$t(
                            "components.thg.ThgBatchUpdateItemsDialog.reason"
                          ),
                          rules: _vm.requiredRules,
                          outlined: "",
                          dense: ""
                        },
                        model: {
                          value: _vm.reason,
                          callback: function callback($$v) {
                            _vm.reason = $$v
                          },
                          expression: "reason"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "p",
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgBatchUpdateItemsDialog.instruction"
                                )
                              ) +
                              " "
                          ),
                          _c("thg-batch-select-dialog", {
                            attrs: {
                              typeLock: true,
                              type: _vm.type
                            },
                            on: {
                              select: _vm.setThgsToAdd
                            }
                          })
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgBatchUpdateItemsDialog.addedQuotes"
                            )
                          ) +
                            " " +
                            _vm._s(_vm.numberSelected)
                        )
                      ])
                    ]
                  ),
                  _c("v-col", {
                    attrs: {
                      cols: "12"
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        title: _vm.$t(
          "timeLine.ThgQuoteDetailCardActivityTimeLineSideCard.title"
        ),
        width: "600px"
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "v-timeline",
        {
          staticClass: "mr-2 ml-2 pb-4",
          attrs: {
            dense: "",
            clipped: ""
          }
        },
        [
          _vm.activityLog && _vm.activityLog.length
            ? _c("activity-time-line")
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card",
        {
          attrs: {
            outlined: "",
            flat: ""
          }
        },
        [
          _c(
            "v-tabs",
            {
              attrs: {
                "align-with-title": ""
              },
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c("v-tabs-slider"),
              _c("v-tab", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                      )
                    ) +
                    " "
                )
              ]),
              _vm.canCreateMessage
                ? _c("v-tab", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.email"
                          )
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.tab,
                callback: function callback($$v) {
                  _vm.tab = $$v
                },
                expression: "tab"
              }
            },
            [
              _c(
                "v-tab-item",
                {
                  key: "0"
                },
                [
                  _c("report-activity-box-comment-component", {
                    attrs: {
                      thg: _vm.thg,
                      isEditorMobile: true
                    }
                  })
                ],
                1
              ),
              _vm.canCreateMessage
                ? _c(
                    "v-tab-item",
                    {
                      key: "1"
                    },
                    [
                      _c("report-activity-box-mail-component", {
                        attrs: {
                          thg: _vm.thg,
                          isEditorMobile: true,
                          partner: _vm.partner,
                          user: _vm.selectedUser
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
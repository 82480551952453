var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-split", {
    scopedSlots: _vm._u([
      {
        key: "list",
        fn: function fn() {
          return [
            _c("thg-billing-partner-list", {
              attrs: {
                loading: _vm.loadingAll,
                isLoadingDetail: _vm.isLoadingDetail,
                isLoadingMore: _vm.isLoadingMore,
                getModule: _vm.getModule
              },
              on: {
                set: _vm.set,
                loadMore: _vm.loadMore,
                refresh: _vm.refresh,
                updateBillingType: _vm.updateBillingType
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "content",
        fn: function fn() {
          return [
            !_vm.isLoadingDetail
              ? _c("thg-billing-number-partner-detail")
              : _c("v-skeleton-loader", {
                  attrs: {
                    type:
                      "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
                  }
                })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-2"
        },
        [
          _c("v-text-field", {
            attrs: {
              type: "number",
              label: _vm.$t("components.thg.thgBillingList.billingNumber"),
              outlined: "",
              value: _vm.billingNumber + 1,
              disabled: ""
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-n4"
        },
        [
          _c("thg-billing-creditor-selection-user-list", {
            attrs: {
              userId: _vm.value.userId
            },
            on: {
              "update:userId": function updateUserId($event) {
                return _vm.$set(_vm.value, "userId", $event)
              },
              "update:user-id": function updateUserId($event) {
                return _vm.$set(_vm.value, "userId", $event)
              }
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-n4"
        },
        [
          _c("v-text-field", {
            attrs: {
              type: "number",
              label: _vm.$t("components.thg.thgBillingList.accountNumber"),
              outlined: ""
            },
            model: {
              value: _vm.value.accountNumber,
              callback: function callback($$v) {
                _vm.$set(_vm.value, "accountNumber", $$v)
              },
              expression: "value.accountNumber"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-n4"
        },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.thg.thgBillingList.thgId"),
              outlined: ""
            },
            model: {
              value: _vm.value.thgId,
              callback: function callback($$v) {
                _vm.$set(_vm.value, "thgId", $$v)
              },
              expression: "value.thgId"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-n4"
        },
        [
          _c("v-text-field", {
            attrs: {
              label: _vm.$t("components.thg.thgBillingList.partnerId"),
              outlined: ""
            },
            model: {
              value: _vm.value.partnerId,
              callback: function callback($$v) {
                _vm.$set(_vm.value, "partnerId", $$v)
              },
              expression: "value.partnerId"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ml-5 mr-5 mt-n4"
        },
        [
          _c("v-combobox", {
            attrs: {
              items: _vm.typeLabels,
              type: "string",
              label: _vm.$t("components.thg.thgBillingList.types"),
              outlined: ""
            },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function fn(_ref) {
                  var item = _ref.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingCreateNewBillingManual." +
                              item
                          )
                        ) +
                        " "
                    )
                  ]
                }
              },
              {
                key: "item",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingCreateNewBillingManual." +
                              item
                          )
                        ) +
                        " "
                    )
                  ]
                }
              }
            ]),
            model: {
              value: _vm.value.type,
              callback: function callback($$v) {
                _vm.$set(_vm.value, "type", $$v)
              },
              expression: "value.type"
            }
          })
        ],
        1
      ),
      _c("v-spacer")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
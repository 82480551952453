var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "d-inline"
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            loading: _vm.isLoading,
            icon: ""
          },
          on: {
            click: function click($event) {
              _vm.isDialogActive = true
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-key-change")])],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            "max-width": "800",
            isDialogActive: _vm.isDialogActive,
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            title: _vm.$t(_vm.title),
            subtitle: _vm.subTitle,
            hideRight: true,
            leftText: _vm.$t(_vm.btnText),
            leftColor: "warning",
            leftLoading: _vm.isLoading,
            leftDisabled: !_vm.isFormValid
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            leftClick: _vm.transfer
          }
        },
        [
          _c(
            "v-radio-group",
            {
              staticClass: "mx-6",
              attrs: {
                row: ""
              },
              on: {
                change: _vm.clearFormInputs
              },
              model: {
                value: _vm.radioGroup,
                callback: function callback($$v) {
                  _vm.radioGroup = $$v
                },
                expression: "radioGroup"
              }
            },
            [
              _c("v-radio", {
                attrs: {
                  label: "Partner",
                  value: "partnerId",
                  "data-test-form-iscompany": ""
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: "User",
                  value: "userId",
                  "data-test-form-isprivate": ""
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: "Charging Station",
                  value: "chargingStationId",
                  "data-test-form-ischargingstation": ""
                }
              })
            ],
            1
          ),
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.isFormValid,
                callback: function callback($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _vm.transferToPartner
                ? _c("v-autocomplete", {
                    attrs: {
                      items: _vm.partners,
                      rules: _vm.requiredRules,
                      "item-text": "companyName",
                      "persistent-hint": "",
                      "return-object": "",
                      outlined: ""
                    },
                    model: {
                      value: _vm.partner,
                      callback: function callback($$v) {
                        _vm.partner = $$v
                      },
                      expression: "partner"
                    }
                  })
                : _vm.transferToUser
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      rules: _vm.requiredRules,
                      label: "UserId"
                    },
                    model: {
                      value: _vm.userId,
                      callback: function callback($$v) {
                        _vm.userId = $$v
                      },
                      expression: "userId"
                    }
                  })
                : _vm.transferToChargingStation
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      rules: _vm.requiredRules,
                      label: "ChargingStationId"
                    },
                    model: {
                      value: _vm.chargingStationId,
                      callback: function callback($$v) {
                        _vm.chargingStationId = $$v
                      },
                      expression: "chargingStationId"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
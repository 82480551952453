var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t(
          "components.thg.thgAdvertismentAdminInformationCard.title"
        )
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _vm.disabled
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref) {
                              var on = _ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: _vm.enableChanges
                                      }
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-pencil")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1051792459
                      )
                    },
                    [_c("span", [_vm._v(_vm._s("Bearbeiten"))])]
                  )
                : _vm._e(),
              !_vm.disabled && !_vm.loading
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _vm._e(),
              !_vm.disabled
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        loading: _vm.loading
                      },
                      on: {
                        click: _vm.save
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-content-save-outline")])],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.disabled
        ? _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.costPerPlayout,
                  outlined: "",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.costsPerUnit"
                  ),
                  disabled: ""
                }
              }),
              _c("v-text-field", {
                attrs: {
                  value: _vm.currentlyUsedBudget,
                  outlined: "",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.currentlyUsedBudget"
                  ),
                  disabled: ""
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.outOfBudget",
                    {
                      value: _vm.ad.isOutOfBudget.toString()
                    }
                  ),
                  disabled: ""
                },
                model: {
                  value: _vm.ad.isOutOfBudget,
                  callback: function callback($$v) {
                    _vm.$set(_vm.ad, "isOutOfBudget", $$v)
                  },
                  expression: "ad.isOutOfBudget"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.optin",
                    {
                      value: _vm.ad.isMarketingOptInRequired.toString()
                    }
                  ),
                  disabled: ""
                },
                model: {
                  value: _vm.ad.isMarketingOptInRequired,
                  callback: function callback($$v) {
                    _vm.$set(_vm.ad, "isMarketingOptInRequired", $$v)
                  },
                  expression: "ad.isMarketingOptInRequired"
                }
              })
            ],
            1
          )
        : _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  type: "number",
                  prefix: "€",
                  step: "1",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.costsPerUnit"
                  ),
                  hint: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.costPerUnitHint"
                  ),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.updateDto.costPerPlayout,
                  callback: function callback($$v) {
                    _vm.$set(_vm.updateDto, "costPerPlayout", _vm._n($$v))
                  },
                  expression: "updateDto.costPerPlayout"
                }
              }),
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  type: "number",
                  prefix: "€",
                  step: "1",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.currentlyUsedBudget"
                  ),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.updateDto.currentlyUsedBudget,
                  callback: function callback($$v) {
                    _vm.$set(_vm.updateDto, "currentlyUsedBudget", _vm._n($$v))
                  },
                  expression: "updateDto.currentlyUsedBudget"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.outOfBudget",
                    {
                      value: _vm.ad.isOutOfBudget.toString()
                    }
                  ),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.updateDto.isOutOfBudget,
                  callback: function callback($$v) {
                    _vm.$set(_vm.updateDto, "isOutOfBudget", $$v)
                  },
                  expression: "updateDto.isOutOfBudget"
                }
              }),
              _c("v-switch", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgAdvertismentAdminInformationCard.optin",
                    {
                      value: _vm.ad.isMarketingOptInRequired.toString()
                    }
                  ),
                  disabled: _vm.loading
                },
                model: {
                  value: _vm.updateDto.isMarketingOptInRequired,
                  callback: function callback($$v) {
                    _vm.$set(_vm.updateDto, "isMarketingOptInRequired", $$v)
                  },
                  expression: "updateDto.isMarketingOptInRequired"
                }
              })
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
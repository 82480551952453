var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        title: _vm.$t(
          "components.thg.ThgQuoteMeterReadingWorkItemCard.chartTitle"
        )
      }
    },
    [
      _c(
        "v-card",
        {
          style: "width: 100%; height: 285px;"
        },
        [
          _c("v-chart", {
            staticClass: "barChart",
            attrs: {
              loading: false,
              option: _vm.chartOption,
              autoresize: ""
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: "",
        width: _vm.width,
        title: _vm.$t(
          "components.thg.thgBillingSelectionItemAccountingResult.title"
        ),
        margin: 0
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref) {
                        var on = _ref.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                attrs: {
                                  icon: "",
                                  loading: _vm.accountRecordsLoading,
                                  disabled: _vm.locked
                                },
                                on: {
                                  click: _vm.handleRegenerate
                                }
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-refresh")])],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "components.thg.thgBillingSelectionItemAccountingResult.regenerate"
                        )
                      ) + " "
                    )
                  ])
                ]
              ),
              _c(
                "confirm-action-dialog",
                {
                  attrs: {
                    fullscreen: _vm.$vuetify.breakpoint.smAndDown,
                    isDialogActive: _vm.isRegenerateDialogActive,
                    rightLoading: _vm.accountRecordsLoading,
                    supressKeyboardCancel: true
                  },
                  on: {
                    "update:isDialogActive": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isRegenerateDialogActive = $event
                    },
                    "update:is-dialog-active": function updateIsDialogActive(
                      $event
                    ) {
                      _vm.isRegenerateDialogActive = $event
                    },
                    leftClick: function leftClick($event) {
                      _vm.isRegenerateDialogActive = false
                    },
                    rightClick: _vm.regenerate
                  }
                },
                [
                  _vm.billing.type === _vm.BillingTypeEnum.CREDIT_PARTNER
                    ? _c(
                        "div",
                        _vm._l(_vm.partnerCommissions, function(
                          partnerCommission
                        ) {
                          return _c(
                            "div",
                            {
                              key: partnerCommission.year
                            },
                            [
                              _c("v-text-field", {
                                attrs: {
                                  rounded: "",
                                  outlined: "",
                                  type: "number",
                                  label: "Commission ".concat(
                                    partnerCommission.year
                                  )
                                },
                                model: {
                                  value: partnerCommission.partnerCommission,
                                  callback: function callback($$v) {
                                    _vm.$set(
                                      partnerCommission,
                                      "partnerCommission",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "partnerCommission.partnerCommission"
                                }
                              })
                            ],
                            1
                          )
                        }),
                        0
                      )
                    : _vm._e(),
                  _vm.billing.type ===
                  _vm.BillingTypeEnum.CREDIT_CHARGING_STATION
                    ? _c("v-text-field", {
                        attrs: {
                          rounded: "",
                          outlined: "",
                          type: "number",
                          label: "pricePerKwH"
                        },
                        model: {
                          value: _vm.pricePerKwH,
                          callback: function callback($$v) {
                            _vm.pricePerKwH = $$v
                          },
                          expression: "pricePerKwH"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _vm.billing
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref2) {
                              var on = _ref2.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        loading: _vm.accountRecordsLoading,
                                        disabled: _vm.locked
                                      },
                                      on: {
                                        click: _vm.confirmEdit
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _vm.locked
                                      ? _c("v-icon", [_vm._v("mdi-check")])
                                      : _c(
                                          "v-icon",
                                          {
                                            staticClass: "unsavedData",
                                            attrs: {
                                              color: "red",
                                              "x-large": ""
                                            }
                                          },
                                          [_vm._v("mdi-check")]
                                        )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        2963225463
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemAccountingResult.confirmEdit"
                            )
                          ) + " "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.billing
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref3) {
                              var on = _ref3.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: function click($event) {
                                          _vm.locked = !_vm.locked
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.locked
                                            ? "mdi-lock-outline"
                                            : "mdi-lock-open"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        3431616200
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionBatchData.unlock"
                            )
                          ) + " "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-actions",
        [
          _vm.billing && !_vm.accountRecordsLoading
            ? _c(
                "v-form",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [
                  _c(
                    "v-window",
                    {
                      model: {
                        value: _vm.accountRecordIndex,
                        callback: function callback($$v) {
                          _vm.accountRecordIndex = $$v
                        },
                        expression: "accountRecordIndex"
                      }
                    },
                    [
                      _vm._l(_vm.accountRecords, function(
                        accountRecord,
                        index
                      ) {
                        return _c(
                          "v-window-item",
                          {
                            key: index,
                            attrs: {
                              value: index
                            }
                          },
                          [
                            accountRecord
                              ? _c(
                                  "div",
                                  [
                                    _c("v-text-field", {
                                      staticClass: "mb-n2",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.thg.thgBillingSelectionItemAccountingResult.amount"
                                        ),
                                        type: "number",
                                        step: "0.01",
                                        outlined: "",
                                        dense: "",
                                        flat: "",
                                        disabled: _vm.locked
                                      },
                                      model: {
                                        value: accountRecord.amount,
                                        callback: function callback($$v) {
                                          _vm.$set(accountRecord, "amount", $$v)
                                        },
                                        expression: "accountRecord.amount"
                                      }
                                    }),
                                    _c("v-combobox", {
                                      staticClass: "mb-n2",
                                      attrs: {
                                        items: ["S", "H"],
                                        label: _vm.$t(
                                          "components.thg.thgBillingSelectionItemAccountingResult.debitOrCredit"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        flat: "",
                                        disabled: _vm.locked
                                      },
                                      model: {
                                        value: accountRecord.debitOrCredit,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            accountRecord,
                                            "debitOrCredit",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "accountRecord.debitOrCredit"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      staticClass: "mb-n2",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.thg.thgBillingSelectionItemAccountingResult.account"
                                        ),
                                        type: "number",
                                        outlined: "",
                                        dense: "",
                                        flat: "",
                                        disabled: _vm.locked
                                      },
                                      model: {
                                        value: accountRecord.account,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            accountRecord,
                                            "account",
                                            $$v
                                          )
                                        },
                                        expression: "accountRecord.account"
                                      }
                                    }),
                                    _c("v-text-field", {
                                      staticClass: "mb-n2",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.thg.thgBillingSelectionItemAccountingResult.contraAccount"
                                        ),
                                        type: "number",
                                        outlined: "",
                                        dense: "",
                                        flat: "",
                                        disabled: _vm.locked
                                      },
                                      model: {
                                        value: accountRecord.contraAccount,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            accountRecord,
                                            "contraAccount",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "accountRecord.contraAccount"
                                      }
                                    }),
                                    _c("v-textarea", {
                                      staticClass: "mb-n2",
                                      attrs: {
                                        label: _vm.$t(
                                          "components.thg.thgBillingSelectionItemAccountingResult.bookingText"
                                        ),
                                        outlined: "",
                                        dense: "",
                                        "auto-grow": "",
                                        rows: "1",
                                        flat: "",
                                        disabled: _vm.locked
                                      },
                                      model: {
                                        value: accountRecord.bookingText,
                                        callback: function callback($$v) {
                                          _vm.$set(
                                            accountRecord,
                                            "bookingText",
                                            $$v
                                          )
                                        },
                                        expression: "accountRecord.bookingText"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        )
                      }),
                      _c(
                        "v-window-item",
                        {
                          attrs: {
                            value: _vm.accountRecords.length
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                text: "",
                                width: "232",
                                disabled: _vm.locked
                              },
                              on: {
                                click: _vm.addAccountingStatement
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.thgBillingSelectionItemAccountingResult.addAccountingStatement"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-row",
                        {
                          staticClass: "ma-2"
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: _vm.onLeftClick
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c("v-spacer"),
                          _c(
                            "h6",
                            {
                              staticClass: "ma-3 ml-4"
                            },
                            [_vm._v("#" + _vm._s(_vm.accountRecordIndex + 1))]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                "nudge-right": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function fn(_ref4) {
                                      var on = _ref4.on
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              staticClass: "mr-2",
                                              attrs: {
                                                icon: "",
                                                disabled: _vm.locked
                                              },
                                              on: {
                                                click: function click() {
                                                  return _vm.removeAccountRecord(
                                                    _vm.accountRecordIndex
                                                  )
                                                }
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-trash-can-outline")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                53288229
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.thgBillingSelectionItemAccountingResult.remove"
                                    )
                                  ) + " "
                                )
                              ])
                            ]
                          ),
                          _c("v-spacer"),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: _vm.onRightClick
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    2
                  )
                ],
                1
              )
            : !_vm.accountRecordsLoading
            ? _c("latest-entries-card-empty")
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
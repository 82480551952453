var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v(
          _vm._s(_vm.$t("components.thg.ThgMeterReadingCreateBatch.title"))
        )
      ]),
      _c("v-card-text", [
        _vm._v(
          _vm._s(
            _vm.$t("components.thg.ThgMeterReadingCreateBatch.description")
          )
        )
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                color: "success",
                outlined: ""
              },
              on: {
                click: _vm.generateTemplate
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("components.thg.ThgMeterReadingCreateBatch.template")
                )
              )
            ]
          ),
          _c("excel-import", {
            attrs: {
              config: _vm.config,
              btnText: "components.thg.ThgMeterReadingCreateBatch.import",
              loading: _vm.loading
            },
            on: {
              uploadData: _vm.importData,
              finish: _vm.goToChargingStation
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function fn(slotProps) {
                  return [
                    _c(
                      "v-alert",
                      {
                        staticClass: "ma-4",
                        attrs: {
                          value: true,
                          type: "success",
                          border: "left"
                        }
                      },
                      [
                        _c(
                          "v-row",
                          {
                            attrs: {
                              align: "center"
                            }
                          },
                          [
                            _c(
                              "v-col",
                              {
                                staticClass: "grow"
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.thg.ThgMeterReadingCreateBatch.success",
                                        {
                                          success: _vm.dataImports.length,
                                          all: slotProps.data.length
                                        }
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.failedImports.length
                      ? _c(
                          "v-alert",
                          {
                            staticClass: "ma-4",
                            attrs: {
                              value: true,
                              type: "error",
                              border: "left"
                            }
                          },
                          [
                            _c(
                              "v-row",
                              {
                                attrs: {
                                  align: "center"
                                }
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "grow"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgMeterReadingCreateBatch.error",
                                            {
                                              value: _vm.failedImports.length
                                            }
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                ),
                                _c(
                                  "v-col",
                                  {
                                    staticClass: "shrink"
                                  },
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          small: "",
                                          elevation: "0"
                                        },
                                        on: {
                                          click: _vm.createErrorReport
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t(
                                              "components.thg.ThgMeterReadingCreateBatch.errorReport"
                                            )
                                          )
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
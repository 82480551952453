var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c("table-wrapper", {
        attrs: {
          title: _vm.i18n["title"],
          loading: _vm.loading,
          headers: _vm.headers,
          allItems: _vm.dups,
          selectedItems: _vm.selectedDups
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selectedDups = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selectedDups = $event
          },
          "click:row": _vm.goToDups
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c("v-autocomplete", {
                  staticClass: "searchField",
                  attrs: {
                    dense: "",
                    items: _vm.years,
                    "single-line": "",
                    "hide-details": "",
                    outlined: ""
                  },
                  on: {
                    change: _vm.getDups
                  },
                  model: {
                    value: _vm.year,
                    callback: function callback($$v) {
                      _vm.year = $$v
                    },
                    expression: "year"
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
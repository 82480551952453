var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.onResize,
          expression: "onResize"
        }
      ],
      attrs: {
        width: !_vm.fullscreenBreakpoint ? "1200px" : "100%",
        loading: _vm.loading,
        hideTitle: true,
        fullscreen: _vm.fullscreenBreakpoint,
        absolute: true
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "pa-1",
          style: "height: calc("
            .concat(_vm.height, "px - ")
            .concat(
              _vm.fullscreenBreakpoint ? "4px" : "68px",
              "); overflow-y: auto; overflow-x:hidden"
            )
        },
        [
          _c("efahrer-detail-card", {
            attrs: {
              value: _vm.value,
              loading: _vm.loading
            },
            on: {
              thgCreated: function thgCreated($event) {
                return _vm.$emit("close")
              }
            }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
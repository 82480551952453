var render = function() {
  var _vm$zip

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: _vm.i18n["title"]
      }
    },
    [
      _vm.zipsLoaded
        ? _c("table-wrapper", {
            attrs: {
              emptyItem: _vm.emptyZip,
              confirmNewItems: _vm.confirmNewZips,
              controlElements: _vm.controlElements,
              headers: _vm.headers,
              allItems:
                ((_vm$zip = _vm.zip) === null || _vm$zip === void 0
                  ? void 0
                  : _vm$zip.zipList) || [],
              selectedItems: _vm.selected
            },
            on: {
              "update:selectedItems": function updateSelectedItems($event) {
                _vm.selected = $event
              },
              "update:selected-items": function updateSelectedItems($event) {
                _vm.selected = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "bodyActions",
                  fn: function fn() {
                    return [
                      _c("zip-import-dialog", {
                        on: {
                          refresh: _vm.loadAll
                        }
                      })
                    ]
                  },
                  proxy: true
                }
              ],
              null,
              false,
              1860232852
            )
          })
        : _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type:
                "card-heading, divider, list-item-two-line,list-item-two-line"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
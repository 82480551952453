var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    [
      _c("v-card-title", [
        _vm._v(
          _vm._s(_vm.$t("components.thg.ThgChargingStationCreateView.title"))
        )
      ]),
      _c("v-card-text", [
        _vm._v(
          _vm._s(
            _vm.$t("components.thg.ThgChargingStationCreateView.description")
          ) + " "
        )
      ]),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                color: "success",
                outlined: ""
              },
              on: {
                click: _vm.goToChargingStationNewSingle
              }
            },
            [
              _vm._v(
                _vm._s(
                  _vm.$t("components.thg.ThgChargingStationCreateView.btnText")
                )
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ml-3",
      attrs: {
        outlined: "",
        width: _vm.width,
        title: _vm.$t("components.thg.thgBillingSelectionThgBaseData.title"),
        margin: 0
      }
    },
    [
      _c(
        "v-card-actions",
        [
          _vm.thgBaseData
            ? _c(
                "v-form",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionThgBaseData.id"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.thgBaseData.id,
                      callback: function callback($$v) {
                        _vm.$set(_vm.thgBaseData, "id", $$v)
                      },
                      expression: "thgBaseData.id"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionThgBaseData.userId"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.thgBaseData.userId,
                      callback: function callback($$v) {
                        _vm.$set(_vm.thgBaseData, "userId", $$v)
                      },
                      expression: "thgBaseData.userId"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionThgBaseData.partnerId"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.thgBaseData.partnerId,
                      callback: function callback($$v) {
                        _vm.$set(_vm.thgBaseData, "partnerId", $$v)
                      },
                      expression: "thgBaseData.partnerId"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionThgBaseData.countryCode"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.thgBaseData.countryCode,
                      callback: function callback($$v) {
                        _vm.$set(_vm.thgBaseData, "countryCode", $$v)
                      },
                      expression: "thgBaseData.countryCode"
                    }
                  })
                ],
                1
              )
            : !_vm.loading
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [_c("latest-entries-card-empty")],
                1
              )
            : _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type:
                    "list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
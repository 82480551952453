var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t(
          "components.thg.ThgBatchDetailInformationStatusCard.title"
        )
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _vm.disabled || _vm.isLoading
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        icon: ""
                      },
                      on: {
                        click: function click($event) {
                          return _vm.edit()
                        }
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          }
                        },
                        [_vm._v("mdi-pencil")]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-span",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.close()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "grey lighten-1"
                              }
                            },
                            [_vm._v("mdi-close")]
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              return _vm.save()
                            }
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "grey lighten-1"
                              }
                            },
                            [_vm._v("mdi-content-save")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-text",
        [
          !_vm.disabled && !_vm.loading
            ? _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        sm: "5",
                        cols: "12"
                      }
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.stati,
                          readonly: _vm.disabled,
                          "persistent-hint": ""
                        },
                        model: {
                          value: _vm.model,
                          callback: function callback($$v) {
                            _vm.model = $$v
                          },
                          expression: "model"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        sm: "7",
                        cols: "12"
                      }
                    },
                    [
                      _c("v-checkbox", {
                        attrs: {
                          label: _vm.$t(
                            "components.thg.ThgBatchDetailInformationStatusCard.label"
                          )
                        },
                        model: {
                          value: _vm.userNotification,
                          callback: function callback($$v) {
                            _vm.userNotification = $$v
                          },
                          expression: "userNotification"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _c("v-select", {
                attrs: {
                  items: _vm.stati,
                  readonly: _vm.disabled,
                  "persistent-hint": ""
                },
                model: {
                  value: _vm.model,
                  callback: function callback($$v) {
                    _vm.model = $$v
                  },
                  expression: "model"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
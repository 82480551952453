var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.isUbaFormat
        ? _c("table-wrapper", {
            attrs: {
              allItems: _vm.data,
              headers: _vm.headers,
              showSelect: true,
              selectedItems: _vm.selectedThgs
            },
            on: {
              "update:selectedItems": function updateSelectedItems($event) {
                _vm.selectedThgs = $event
              },
              "update:selected-items": function updateSelectedItems($event) {
                _vm.selectedThgs = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.partnerCommission",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _vm._v(
                        " " + _vm._s(_vm.calculateCommissionForItem(item)) + " "
                      )
                    ]
                  }
                },
                {
                  key: "item.payoutAmount",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.calculatePayoutAmountReadable(item)) +
                          " "
                      )
                    ]
                  }
                },
                {
                  key: "item.promotion",
                  fn: function fn(_ref3) {
                    var item = _ref3.item
                    return [
                      item.promotionConfiguration &&
                      item.promotionConfiguration.value
                        ? _c("div", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "common.PromotionTypeEnum.".concat(
                                      item.promotionConfiguration.type
                                    )
                                  )
                                )
                            ),
                            _c("br"),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.promotionConfiguration.value.toLocaleString(
                                    "de-DE",
                                    {
                                      style: "currency",
                                      currency: "EUR"
                                    }
                                  )
                                ) +
                                " "
                            )
                          ])
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "bodyActions",
                  fn: function fn() {
                    return [
                      _vm.isVehicle && _vm.isDeleteDisabled
                        ? _c("thg-batch-update-items-by-vin-dialog", {
                            staticClass: "ml-1 mr-1",
                            attrs: {
                              thgs: _vm.data
                            },
                            on: {
                              parsed: _vm.updateSelected
                            }
                          })
                        : _vm._e(),
                      _c(
                        "debug",
                        [
                          _vm.isVehicle && _vm.isDeleteDisabled
                            ? _c("thg-quote-export-dialog", {
                                attrs: {
                                  thgs: _vm.data,
                                  disableStatiSelector: true
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      ),
                      _vm.isDeleteDisabled
                        ? _c("thg-batch-update-items-dialog")
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: _vm.download
                          }
                        },
                        [_c("v-icon", [_vm._v("mdi-download")])],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "selectActions",
                  fn: function fn() {
                    return [
                      _c("delete-dialog", {
                        attrs: {
                          isBlock: false,
                          disabled: _vm.isDeleteDisabled,
                          title: _vm.deleteDialogTitle,
                          loading: _vm.isLoadingThg
                        },
                        on: {
                          delete: _vm.removeItemsFromBatch
                        }
                      }),
                      _c("thg-batch-update-selected-status-dialog", {
                        attrs: {
                          loading: _vm.isLoadingUpdate,
                          thgs: _vm.selectedThgs,
                          type: _vm.batch.batch.type
                        }
                      })
                    ]
                  },
                  proxy: true
                },
                {
                  key: "item.id",
                  fn: function fn(_ref4) {
                    var item = _ref4.item
                    return [
                      _vm.isVehicle
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.goToThg(item)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.id) + " ")]
                          )
                        : _vm._e(),
                      !_vm.isVehicle
                        ? _c(
                            "a",
                            {
                              on: {
                                click: function click($event) {
                                  return _vm.goToMeterReading(item)
                                }
                              }
                            },
                            [_vm._v(" " + _vm._s(item.id) + " ")]
                          )
                        : _vm._e()
                    ]
                  }
                },
                {
                  key: "item.userId",
                  fn: function fn(_ref5) {
                    var item = _ref5.item
                    return [
                      _c(
                        "a",
                        {
                          on: {
                            click: function click($event) {
                              return _vm.goToUser(item)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.userId) + " ")]
                      )
                    ]
                  }
                }
              ],
              null,
              false,
              3104765380
            )
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
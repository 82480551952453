import { render, staticRenderFns } from "./ThgEditImagesDialog.vue?vue&type=template&id=e4457cfe&"
import script from "./ThgEditImagesDialog.vue?vue&type=script&lang=ts&"
export * from "./ThgEditImagesDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCardSubtitle,VCardTitle,VChip,VCol,VDialog,VDivider,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VRow,VSelect,VSkeletonLoader,VSpacer,VTooltip,VVirtualScroll})


/* vuetify-loader */
import installDirectives from "!../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Resize from 'vuetify/lib/directives/resize'
installDirectives(component, {Resize})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e4457cfe')) {
      api.createRecord('e4457cfe', component.options)
    } else {
      api.reload('e4457cfe', component.options)
    }
    module.hot.accept("./ThgEditImagesDialog.vue?vue&type=template&id=e4457cfe&", function () {
      api.rerender('e4457cfe', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgEditImagesDialog.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c("v-progress-linear", {
        attrs: {
          indeterminate:
            _vm.loadingCreation ||
            _vm.loadingDataForBilledBeing ||
            _vm.isLoading
        }
      }),
      _c(
        "card",
        {
          attrs: {
            title: _vm.$t(
              "components.thg.thgBillingCreateNewBillingManual.".concat(
                _vm.billingType
              )
            ),
            outlined: false
          },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function fn() {
                return [
                  _vm.billedBeings
                    ? _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "center"
                          }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                disabled:
                                  _vm.loadingCreation ||
                                  _vm.selectedWindow === 0 ||
                                  _vm.loadingDataForBilledBeing,
                                loading:
                                  _vm.loadingCreation ||
                                  _vm.loadingDataForBilledBeing
                              },
                              on: {
                                click: _vm.goToPreviousWindow
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          ),
                          _c("h6", [
                            _vm._v(
                              _vm._s(_vm.selectedWindow + 1) +
                                "/" +
                                _vm._s(_vm.billedBeings.length)
                            )
                          ]),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                icon: "",
                                disabled:
                                  _vm.loadingCreation ||
                                  _vm.selectedWindow ===
                                    _vm.billedBeings.length - 1 ||
                                  _vm.loadingDataForBilledBeing,
                                loading:
                                  _vm.loadingCreation ||
                                  _vm.loadingDataForBilledBeing
                              },
                              on: {
                                click: _vm.goToNextWindow
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.selectedBilledBeing && _vm.selectedBilledBeing.contact
                    ? _c("template-dialog-partner-selection", {
                        attrs: {
                          context: {
                            user: _vm.selectedBilledBeing,
                            billing: _vm.createdBilling
                          },
                          to: [_vm.selectedBilledBeing.contact.email],
                          partners: _vm.partners
                        }
                      })
                    : _vm._e(),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.isLoading || _vm.loadingCreation,
                        disabled: _vm.isLoading || _vm.loadingCreation,
                        text: "",
                        outlined: "",
                        color: "success"
                      },
                      on: {
                        click: _vm.nonForceCreateBilling
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.i18n["billyTheButton"]) + " ")]
                  ),
                  _vm.billingType === _vm.BillingTypeEnum.AFFILIATE ||
                  _vm.billingType === _vm.BillingTypeEnum.CREDIT_PARTNER
                    ? _c("thg-billing-reject-button", {
                        attrs: {
                          thgIds: _vm.selectedSelectedBilledDocuments.map(
                            function(b) {
                              return b.id
                            }
                          ),
                          billingType: _vm.billingType,
                          loading: _vm.isLoading || _vm.loadingCreation
                        },
                        on: {
                          reject: _vm.reject
                        }
                      })
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "v-window",
            {
              staticClass: "elevation-1",
              attrs: {
                value: _vm.selectedWindow
              }
            },
            [
              _vm.selectedBilledBeing && _vm.selectedBilledDocuments
                ? _c(
                    "div",
                    _vm._l(_vm.billedBeings, function(n, index) {
                      return _c(
                        "v-window-item",
                        {
                          key: n.id
                        },
                        [
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "8"
                                  }
                                },
                                [
                                  index === _vm.selectedWindow &&
                                  !(
                                    _vm.loadingDataForBilledBeing ||
                                    _vm.isLoading
                                  )
                                    ? _c("thg-billing-detail-data-card", {
                                        attrs: {
                                          selectedBilledBeing:
                                            _vm.selectedBilledBeing,
                                          billedDocuments: _vm.billedDocuments,
                                          selectedBilledDocuments:
                                            _vm.selectedBilledDocuments,
                                          selectedSelectedBilledDocuments:
                                            _vm.selectedSelectedBilledDocuments,
                                          banking: _vm.banking,
                                          partnerBankings: _vm.partnerBankings,
                                          partnerBillingInformation:
                                            _vm.partnerBillingInformation,
                                          chargingStations:
                                            _vm.chargingStations,
                                          affiliateCodes: _vm.affiliateCodes,
                                          batches: _vm.batches,
                                          selectedPartners: _vm.partners,
                                          pricePerKwH: _vm.pricePerKwH,
                                          commissions: _vm.commissions,
                                          billingType: _vm.billingType
                                        },
                                        on: {
                                          "update:selectedSelectedBilledDocuments": function updateSelectedSelectedBilledDocuments(
                                            $event
                                          ) {
                                            _vm.selectedSelectedBilledDocuments = $event
                                          },
                                          "update:selected-selected-billed-documents": function updateSelectedSelectedBilledDocuments(
                                            $event
                                          ) {
                                            _vm.selectedSelectedBilledDocuments = $event
                                          },
                                          "update:pricePerKwH": function updatePricePerKwH(
                                            $event
                                          ) {
                                            _vm.pricePerKwH = $event
                                          },
                                          "update:price-per-kw-h": function updatePricePerKwH(
                                            $event
                                          ) {
                                            _vm.pricePerKwH = $event
                                          },
                                          "update:commissions": function updateCommissions(
                                            $event
                                          ) {
                                            _vm.commissions = $event
                                          }
                                        }
                                      })
                                    : _c("v-skeleton-loader", {
                                        attrs: {
                                          type:
                                            "card-heading, divider, card-heading, image, divider, card-heading, image"
                                        }
                                      })
                                ],
                                1
                              ),
                              _c("v-divider", {
                                attrs: {
                                  vertical: ""
                                }
                              }),
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12",
                                    lg: "4"
                                  }
                                },
                                [
                                  _c("thg-billing-detail-results-card", {
                                    attrs: {
                                      commissions: _vm.commissions,
                                      pricePerKwh: _vm.pricePerKwH,
                                      createdBilling: _vm.createdBilling
                                    },
                                    on: {
                                      "update:commissions": function updateCommissions(
                                        $event
                                      ) {
                                        _vm.commissions = $event
                                      },
                                      "update:pricePerKwh": function updatePricePerKwh(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      },
                                      "update:price-per-kwh": function updatePricePerKwh(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            align: "center",
                            justify: "center"
                          }
                        },
                        [
                          _c("iframe", {
                            attrs: {
                              frameBorder: "0",
                              srcdoc:
                                "<img src='https://media.tenor.com/vMvR0gEolCEAAAAS/nothing-to-see-here-explosion.gif' style='width:100%'/>",
                              height: "700px",
                              width: _vm.$vuetify.breakpoint.mdAndUp
                                ? "900px"
                                : "100%",
                              title: _vm.i18n["noData"]
                            }
                          })
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _c(
            "confirm-action-dialog",
            {
              attrs: {
                title: _vm.i18n["forceDialogTitle"],
                isDialogActive: _vm.isForcedDialogActive,
                leftLoading: _vm.loadingCreation,
                rightLoading: _vm.loadingCreation,
                supressKeyboardActions: "mm-hmm"
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isForcedDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isForcedDialogActive = $event
                },
                rightClick: _vm.forceCreateBilling,
                leftClick: function leftClick($event) {
                  _vm.isForcedDialogActive = false
                }
              }
            },
            [_vm._v(" " + _vm._s(_vm.i18n["forceDialogBody"]) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
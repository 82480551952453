var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t("components.thg.thgAdvertismentInformationCard.title")
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.isEditting
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref) {
                              var on = _ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        loading: _vm.loading
                                      },
                                      on: {
                                        click: _vm.edit
                                      }
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-pencil")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        48880309
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgAdvertismentInformationCard.update"
                            )
                          )
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.isEditting && !_vm.loading
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                : _vm._e(),
              _vm.isEditting
                ? _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: "",
                        loading: _vm.loading
                      },
                      on: {
                        click: _vm.save
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-content-save-outline")])],
                    1
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      !_vm.isEditting
        ? _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  value: _vm.monthlyBudget,
                  outlined: "",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentInformationCard.monthlyBudget"
                  ),
                  disabled: ""
                }
              }),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.contentTypeItems,
                  disabled: "",
                  outlined: "",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentInformationCard.type"
                  )
                },
                model: {
                  value: _vm.ad.content.type,
                  callback: function callback($$v) {
                    _vm.$set(_vm.ad.content, "type", $$v)
                  },
                  expression: "ad.content.type"
                }
              }),
              _vm.isText
                ? _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.adText"
                      ),
                      disabled: true,
                      value: _vm.ad.content.text
                    }
                  })
                : _vm._e(),
              _vm.isBanner
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.target"
                      ),
                      disabled: ""
                    },
                    model: {
                      value: _vm.ad.content.targetUrl,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ad.content, "targetUrl", $$v)
                      },
                      expression: "ad.content.targetUrl"
                    }
                  })
                : _vm._e(),
              _vm.isBanner
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.image"
                      ),
                      disabled: ""
                    },
                    model: {
                      value: _vm.ad.content.bannerUrl,
                      callback: function callback($$v) {
                        _vm.$set(_vm.ad.content, "bannerUrl", $$v)
                      },
                      expression: "ad.content.bannerUrl"
                    }
                  })
                : _vm._e(),
              _vm.isBanner
                ? _c("v-img", {
                    attrs: {
                      src: _vm.ad.content.bannerUrl
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-card-text",
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  type: "number",
                  prefix: "€",
                  step: "1",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentInformationCard.monthlyBudget"
                  ),
                  hint: _vm.$t(
                    "components.thg.thgAdvertismentInformationCard.monthlyBudegtHint"
                  )
                },
                model: {
                  value: _vm.createOrUpdateAdvertisement.monthlyBudget,
                  callback: function callback($$v) {
                    _vm.$set(
                      _vm.createOrUpdateAdvertisement,
                      "monthlyBudget",
                      _vm._n($$v)
                    )
                  },
                  expression: "createOrUpdateAdvertisement.monthlyBudget"
                }
              }),
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.contentTypeItems,
                  outlined: "",
                  label: _vm.$t(
                    "components.thg.thgAdvertismentInformationCard.type"
                  )
                },
                model: {
                  value: _vm.createOrUpdateAdvertisement.content.type,
                  callback: function callback($$v) {
                    _vm.$set(
                      _vm.createOrUpdateAdvertisement.content,
                      "type",
                      $$v
                    )
                  },
                  expression: "createOrUpdateAdvertisement.content.type"
                }
              }),
              _vm.isTextType(_vm.createOrUpdateAdvertisement)
                ? _c("v-textarea", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.adText"
                      )
                    },
                    model: {
                      value: _vm.createOrUpdateAdvertisement.content.text,
                      callback: function callback($$v) {
                        _vm.$set(
                          _vm.createOrUpdateAdvertisement.content,
                          "text",
                          $$v
                        )
                      },
                      expression: "createOrUpdateAdvertisement.content.text"
                    }
                  })
                : _vm._e(),
              _vm.isBannerType(_vm.createOrUpdateAdvertisement)
                ? _c("v-text-field", {
                    attrs: {
                      outlined: "",
                      label: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.target"
                      ),
                      hint: _vm.$t(
                        "components.thg.thgAdvertismentInformationCard.targetHint"
                      )
                    },
                    model: {
                      value: _vm.createOrUpdateAdvertisement.content.targetUrl,
                      callback: function callback($$v) {
                        _vm.$set(
                          _vm.createOrUpdateAdvertisement.content,
                          "targetUrl",
                          $$v
                        )
                      },
                      expression:
                        "createOrUpdateAdvertisement.content.targetUrl"
                    }
                  })
                : _vm._e(),
              _vm.isBannerType(_vm.createOrUpdateAdvertisement)
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "11"
                          }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              outlined: "",
                              label: _vm.$t(
                                "components.thg.thgAdvertismentInformationCard.image"
                              )
                            },
                            model: {
                              value:
                                _vm.createOrUpdateAdvertisement.content
                                  .bannerUrl,
                              callback: function callback($$v) {
                                _vm.$set(
                                  _vm.createOrUpdateAdvertisement.content,
                                  "bannerUrl",
                                  $$v
                                )
                              },
                              expression:
                                "createOrUpdateAdvertisement.content.bannerUrl"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "1"
                          }
                        },
                        [
                          _c("public-image-upload-dialog", {
                            attrs: {
                              prefix: "ad"
                            },
                            on: {
                              onUpload: _vm.handleImage
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isBannerType(_vm.createOrUpdateAdvertisement) &&
              _vm.createOrUpdateAdvertisement.content.bannerUrl
                ? _c("v-img", {
                    attrs: {
                      "max-width": "400",
                      src: _vm.createOrUpdateAdvertisement.content.bannerUrl
                    }
                  })
                : _vm._e()
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-select", {
    staticClass: "mb-n9",
    attrs: {
      items: _vm.displayItems,
      rules: _vm.requiredRule,
      rounded: "",
      flat: "",
      outlined: "",
      dense: "",
      label: _vm.labelText
    },
    on: {
      change: _vm.onChange
    },
    model: {
      value: _vm.selectedItem,
      callback: function callback($$v) {
        _vm.selectedItem = $$v
      },
      expression: "selectedItem"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
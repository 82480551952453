var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "detailContainer"
    },
    [
      !_vm.billingDocuments.loadingBilling && _vm.billingDocuments.billing
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                {
                  staticClass: "title pt-4"
                },
                [
                  _c(
                    "h3",
                    {
                      staticClass: "text-truncate"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingNumberDetail.title"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              ),
              _vm.billingDate
                ? _c(
                    "v-card-subtitle",
                    [
                      _vm._v(" " + _vm._s(_vm.billingDate) + " "),
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            "nudge-right": ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref) {
                                  var on = _ref.on
                                  return [
                                    _vm.displayToDetailView
                                      ? _c(
                                          "v-btn",
                                          _vm._g(
                                            {
                                              attrs: {
                                                icon: ""
                                              },
                                              on: {
                                                click: _vm.toDetailView
                                              }
                                            },
                                            on
                                          ),
                                          [
                                            _c("v-icon", [
                                              _vm._v("mdi-open-in-new")
                                            ])
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2415190056
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.partner.PartnerFormDetail.tooltip.toDetailView"
                                )
                              ) + " "
                            )
                          ])
                        ]
                      ),
                      _c("thg-billing-cancellation-dialog", {
                        attrs: {
                          billing: _vm.billingDocuments.billing
                        }
                      }),
                      _c("thg-billing-recreation-dialog", {
                        attrs: {
                          billing: _vm.billingDocuments.billing
                        }
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "v-tabs",
                {
                  attrs: {
                    "background-color": "transparent",
                    color: "basil",
                    grow: "",
                    "show-arrows": ""
                  },
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c("v-tab", [_vm._v("Billings")]),
                  !_vm.billingDocuments.loadingData
                    ? _c("v-tab", [_vm._v("Data")])
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-tabs-items",
                {
                  model: {
                    value: _vm.tab,
                    callback: function callback($$v) {
                      _vm.tab = $$v
                    },
                    expression: "tab"
                  }
                },
                [
                  _c(
                    "v-tab-item",
                    {
                      key: "0"
                    },
                    [
                      _c(
                        "div",
                        {
                          staticStyle: {
                            display: "flex",
                            "flex-wrap": "wrap"
                          }
                        },
                        [
                          _vm.billingDocuments.billing
                            ? _c(
                                "div",
                                {
                                  staticClass: "billingContainer flexContainer",
                                  staticStyle: {
                                    "flex-grow": "2"
                                  }
                                },
                                [
                                  _c("thg-billing-detail-results-card", {
                                    attrs: {
                                      createdBilling:
                                        _vm.billingDocuments.billing,
                                      commissions: _vm.commissions,
                                      pricePerKwh: _vm.pricePerKwH
                                    },
                                    on: {
                                      "update:commissions": function updateCommissions(
                                        $event
                                      ) {
                                        _vm.commissions = $event
                                      },
                                      "update:pricePerKwh": function updatePricePerKwh(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      },
                                      "update:price-per-kwh": function updatePricePerKwh(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ]
                  ),
                  _c(
                    "Can",
                    {
                      attrs: {
                        I: _vm.ActionEnum.READ,
                        a: _vm.ResourceEnum.THG
                      }
                    },
                    [
                      !_vm.billingDocuments.loadingData
                        ? _c(
                            "v-tab-item",
                            {
                              key: "1"
                            },
                            [
                              !_vm.billingDocuments.loadingData
                                ? _c("thg-billing-detail-data-card", {
                                    attrs: {
                                      noSelect: true,
                                      billingType:
                                        _vm.billingDocuments.billing.type,
                                      selectedBilledBeing:
                                        _vm.billingDocuments.billedBeing,
                                      billedDocuments:
                                        _vm.billingDocuments.billedDocuments,
                                      selectedBilledDocuments:
                                        _vm.billingDocuments.billedDocuments,
                                      selectedSelectedBilledDocuments:
                                        _vm.billingDocuments.billedDocuments,
                                      banking: _vm.billingDocuments.banking,
                                      partnerBillingInformation:
                                        _vm.billingDocuments
                                          .partnerBillingInformation,
                                      chargingStations:
                                        _vm.billingDocuments.chargingStations,
                                      affiliateCodes:
                                        _vm.billingDocuments.affiliateCodes,
                                      batches: _vm.billingDocuments.batches,
                                      selectedPartners:
                                        _vm.billingDocuments.selectedPartners,
                                      commissions: _vm.commissions,
                                      pricePerKwH: _vm.pricePerKwH
                                    },
                                    on: {
                                      "update:commissions": function updateCommissions(
                                        $event
                                      ) {
                                        _vm.commissions = $event
                                      },
                                      "update:pricePerKwH": function updatePricePerKwH(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      },
                                      "update:price-per-kw-h": function updatePricePerKwH(
                                        $event
                                      ) {
                                        _vm.pricePerKwH = $event
                                      }
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm.billingDocuments.loadingBilling
        ? _c("v-skeleton-loader", {
            attrs: {
              type:
                "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
        : _c("latest-entries-card-empty", {
            staticClass: "mt-16"
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
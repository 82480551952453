import { render, staticRenderFns } from "./BatchListCard.vue?vue&type=template&id=31d95b8c&scoped=true&"
import script from "./BatchListCard.vue?vue&type=script&lang=ts&"
export * from "./BatchListCard.vue?vue&type=script&lang=ts&"
import style0 from "./BatchListCard.vue?vue&type=style&index=0&id=31d95b8c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31d95b8c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemActionText } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VCard,VCardTitle,VIcon,VImg,VList,VListItem,VListItemAction,VListItemActionText,VListItemContent,VListItemSubtitle,VListItemTitle,VSkeletonLoader,VSpacer,VTooltip})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('31d95b8c')) {
      api.createRecord('31d95b8c', component.options)
    } else {
      api.reload('31d95b8c', component.options)
    }
    module.hot.accept("./BatchListCard.vue?vue&type=template&id=31d95b8c&scoped=true&", function () {
      api.rerender('31d95b8c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/BatchListCard.vue"
export default component.exports
var render = function() {
  var _vm$thg$countryCode

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t("components.thg.ThgQuoteCountryCodeCard.title"),
        loading: _vm.loading
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c("thg-admin-update-country-code-dialog", {
                attrs: {
                  loading: _vm.loading,
                  thg: _vm.thg
                },
                on: {
                  save: _vm.save
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-list",
            {
              attrs: {
                dense: ""
              }
            },
            [
              _c(
                "v-list-item",
                [
                  _c(
                    "v-list-item-icon",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.getFlag(
                                  ((_vm$thg$countryCode =
                                    _vm.thg.countryCode) === null ||
                                  _vm$thg$countryCode === void 0
                                    ? void 0
                                    : _vm$thg$countryCode.toString()) ||
                                    _vm.CountryCodeEnum.germany
                                )
                              )
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c("v-list-item-subtitle", [
                    _vm._v(" " + _vm._s(_vm.thg.countryCode) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
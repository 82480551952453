var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      class: {
        "ma-0": _vm.$vuetify.breakpoint.smAndDown,
        "ma-2": _vm.$vuetify.breakpoint.mdAndUp
      },
      attrs: {
        loading: _vm.loading,
        outlined: "",
        flat: "",
        title: _vm.$t(_vm.title)
      },
      scopedSlots: _vm._u(
        [
          _vm.isExportEnabled
            ? {
                key: "actions",
                fn: function fn() {
                  return [
                    _c("analytics-data-table-dialog", {
                      attrs: {
                        data: _vm.exportData
                      }
                    })
                  ]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm.shopwKpiGroup
        ? _c(
            "v-card-text",
            [
              _c(
                "v-row",
                _vm._l(_vm.kpiGroup, function(kpi, i2) {
                  return _c("analytics-kpi-column", {
                    key: i2,
                    attrs: {
                      icon: kpi.icon,
                      color: "primary",
                      title: kpi.name,
                      data: kpi.value
                    }
                  })
                }),
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("v-chart", {
        staticClass: "linechart",
        attrs: {
          loading: _vm.loading,
          option: _vm.chartOptions,
          autoresize: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
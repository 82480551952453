var render = function() {
  var _vm$selectedUser,
    _vm$selectedUser$cont,
    _vm$selectedUser2,
    _vm$selectedUser2$con

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "ma-2 mt-8",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-information-status-card", {
                attrs: {
                  thg: _vm.selectedThg,
                  cb: _vm.updateStatusCb
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("thg-quote-country-code-card", {
                attrs: {
                  thg: _vm.selectedThg,
                  cb: _vm.updateCountryCodeCb
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-information-impact-factor-card", {
                attrs: {
                  thg: _vm.selectedThg
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-information-contract-card", {
                attrs: {
                  thg: _vm.selectedThg,
                  loading: _vm.isLoading
                },
                on: {
                  save: _vm.savePayoutConfiguration
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-information-saving-card", {
                attrs: {
                  thg: _vm.selectedThg
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("partner-registration-card", {
                attrs: {
                  loading: _vm.registrationUpdateLoading,
                  registration: _vm.selectedThg.registration,
                  numberplate: _vm.selectedThg.numberplate,
                  registrationImageLink: _vm.registrationImageFront,
                  displayName: true,
                  displayManufacturerTypeCode: false,
                  displayManufacturerCode: false,
                  displayHuDate: false,
                  displayDriveTyp: true,
                  displayVehicleClass: true
                },
                on: {
                  update: _vm.updateRegistration
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                md: "6",
                cols: "12"
              }
            },
            [
              _c("customer-contact-card", {
                attrs: {
                  userId: _vm.selectedUser._id,
                  companyName: _vm.selectedUser.company,
                  customerName: _vm.fullname,
                  address: _vm.selectedUser.address,
                  email:
                    (_vm$selectedUser = _vm.selectedUser) === null ||
                    _vm$selectedUser === void 0
                      ? void 0
                      : (_vm$selectedUser$cont = _vm$selectedUser.contact) ===
                          null || _vm$selectedUser$cont === void 0
                      ? void 0
                      : _vm$selectedUser$cont.email,
                  phone:
                    (_vm$selectedUser2 = _vm.selectedUser) === null ||
                    _vm$selectedUser2 === void 0
                      ? void 0
                      : (_vm$selectedUser2$con = _vm$selectedUser2.contact) ===
                          null || _vm$selectedUser2$con === void 0
                      ? void 0
                      : _vm$selectedUser2$con.phone
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-image-card", {
                attrs: {
                  thg: _vm.selectedThg
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("thg-quote-detail-information-proof-card", {
                attrs: {
                  thg: _vm.selectedThg
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: "",
        "min-width": "250px",
        flat: "",
        title: _vm.$t(
          "components.thg.ThgBatchDetailInformationInstructionCard.title"
        )
      }
    },
    [
      _c(
        "v-timeline",
        {
          attrs: {
            "align-top": "",
            dense: ""
          }
        },
        [
          _vm.isVehicle && !_vm.isMissingData
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4 pr-4",
                  attrs: {
                    color: "primary",
                    small: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "9"
                          }
                        },
                        [_vm._v(" Alle Daten wurden vollständig erfasst")]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right",
                          attrs: {
                            cols: "3"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "success"
                              }
                            },
                            [_vm._v("mdi-check")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isVehicle && _vm.isMissingData
            ? _c(
                "span",
                [
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "mb-4 pr-4",
                      attrs: {
                        color: "primary",
                        small: ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "9"
                              }
                            },
                            [_vm._v(" Daten unvollständig erfasst")]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: {
                                cols: "3"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "error"
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        "hide-dot": ""
                      }
                    },
                    [
                      _vm._v(" Fehlende FINS: " + _vm._s(_vm.emptyVin)),
                      _c("br"),
                      _vm._v(" Fehlende Namen: " + _vm._s(_vm.emptyName)),
                      _c("br"),
                      _vm._v(
                        " Fehlende Fahrzeugklassen: " +
                          _vm._s(_vm.emptyVehicleClass) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isVehicle && _vm.isDups
            ? _c(
                "span",
                [
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "mb-4 pr-4",
                      attrs: {
                        color: "primary",
                        small: ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "9"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgBatchDetailInformationInstructionCard.step.duplicates.isDups"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                          _c(
                            "v-col",
                            {
                              staticClass: "text-right",
                              attrs: {
                                cols: "3"
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "error"
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "mb-4",
                      attrs: {
                        "hide-dot": ""
                      }
                    },
                    [
                      _c("thg-batch-duplicate-check-dialog", {
                        attrs: {
                          thgs: _vm.batch.thgList
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isVehicle && !_vm.isDups
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4 pr-4",
                  attrs: {
                    color: "primary",
                    small: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "9"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgBatchDetailInformationInstructionCard.step.duplicates.noDups"
                                )
                              ) +
                              " "
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right",
                          attrs: {
                            cols: "3"
                          }
                        },
                        [
                          _c(
                            "v-icon",
                            {
                              attrs: {
                                color: "success"
                              }
                            },
                            [_vm._v("mdi-check")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isLoadingChargingStation
            ? _c(
                "span",
                [
                  _c(
                    "v-timeline-item",
                    {
                      staticClass: "mb-4 pr-4",
                      attrs: {
                        color: "primary",
                        small: ""
                      }
                    },
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "9"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgBatchDetailInformationInstructionCard.step.geo.isLoading"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "span",
                [
                  _vm.isMeterreading && _vm.isGeoMissingOnChargingStation
                    ? _c(
                        "v-timeline-item",
                        {
                          staticClass: "mb-4 pr-4",
                          attrs: {
                            color: "primary",
                            small: ""
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-between"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "9"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgBatchDetailInformationInstructionCard.step.geo.isNotOkay"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: {
                                    cols: "3"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "error"
                                      }
                                    },
                                    [_vm._v("mdi-close")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "v-list",
                            [
                              _c(
                                "v-list-item-group",
                                {
                                  attrs: {
                                    color: "primary"
                                  }
                                },
                                _vm._l(
                                  _vm.chargingStationsWithMissingGeo,
                                  function(item, i) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        attrs: {
                                          "three-line": ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-list-item-content",
                                          {
                                            on: {
                                              click: function click($event) {
                                                return _vm.openChargingStation(
                                                  item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            item.chargingPointOperator
                                              ? _c("v-list-item-title", [
                                                  _vm._v(
                                                    _vm._s(
                                                      item.chargingPointOperator
                                                        .name
                                                    )
                                                  )
                                                ])
                                              : _vm._e(),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              )
                                            ]),
                                            _c("v-list-item-subtitle", [
                                              _vm._v(
                                                " " + _vm._s(item.id) + " "
                                              )
                                            ])
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isMeterreading && !_vm.isGeoMissingOnChargingStation
                    ? _c(
                        "v-timeline-item",
                        {
                          staticClass: "mb-4 pr-4",
                          attrs: {
                            color: "primary",
                            small: ""
                          }
                        },
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                justify: "space-between"
                              }
                            },
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "9"
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.thg.ThgBatchDetailInformationInstructionCard.step.geo.isOkay"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c(
                                "v-col",
                                {
                                  staticClass: "text-right",
                                  attrs: {
                                    cols: "3"
                                  }
                                },
                                [
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        color: "success"
                                      }
                                    },
                                    [_vm._v("mdi-check")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              ),
          _vm.isVehicle
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4 pr-4",
                  attrs: {
                    color: "primary",
                    small: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "space-between"
                      }
                    },
                    [
                      _vm.isErrorOnCrop
                        ? _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "9"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgBatchDetailInformationInstructionCard.step.cropping.isNotDone"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        : _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "9"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.thg.ThgBatchDetailInformationInstructionCard.step.cropping.isDone"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          ),
                      _c(
                        "v-col",
                        {
                          staticClass: "text-right",
                          attrs: {
                            cols: "3"
                          }
                        },
                        [
                          _vm.isErrorOnCrop
                            ? _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "error"
                                  }
                                },
                                [_vm._v("mdi-close")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  attrs: {
                                    color: "success"
                                  }
                                },
                                [_vm._v("mdi-check")]
                              )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isVehicle
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4",
                  attrs: {
                    "hide-dot": ""
                  }
                },
                [
                  _c("thg-edit-images-dialog", {
                    attrs: {
                      thgs: _vm.batch.thgList
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-timeline-item",
            {
              staticClass: "mb-4 pr-4",
              attrs: {
                color: "primary",
                small: ""
              }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    justify: "space-between"
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "9"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgBatchDetailInformationInstructionCard.step.export.title"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-timeline-item",
            {
              staticClass: "mb-4",
              attrs: {
                "hide-dot": ""
              }
            },
            [
              _vm.isMeterreading
                ? _c("thg-quote-export-meter-reading-dialog", {
                    attrs: {
                      thgs: _vm.batch.thgList
                    }
                  })
                : _vm._e(),
              _vm.isVehicle
                ? _c("thg-quote-export-thg-vehicle-dialog", {
                    attrs: {
                      thgs: _vm.batch.thgList
                    }
                  })
                : _vm._e()
            ],
            1
          ),
          _vm.isVehicle
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4 pr-4",
                  attrs: {
                    color: "primary",
                    small: ""
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "space-between"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: "9"
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgBatchDetailInformationInstructionCard.step.exportImages.title"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.isVehicle
            ? _c(
                "v-timeline-item",
                {
                  staticClass: "mb-4",
                  attrs: {
                    "hide-dot": ""
                  }
                },
                [
                  _vm.isVehicle
                    ? _c("thg-batch-download-dialog", {
                        attrs: {
                          thgs: _vm.batch.thgList,
                          batch: _vm.batch
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c("thg-edit-image", {
        scopedSlots: _vm._u([
          {
            key: "navrow",
            fn: function fn() {
              return [
                _c("v-select", {
                  staticClass: "mb-n6",
                  attrs: {
                    items: _vm.possibleLabels,
                    outlined: ""
                  },
                  on: {
                    change: _vm.debounceUpdate
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "selection",
                      fn: function fn(_ref) {
                        var item = _ref.item
                        return [_vm._v(_vm._s(_vm.label(item)))]
                      }
                    },
                    {
                      key: "item",
                      fn: function fn(_ref2) {
                        var item = _ref2.item
                        return [_vm._v(_vm._s(_vm.label(item)))]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.currentLabel,
                    callback: function callback($$v) {
                      _vm.currentLabel = $$v
                    },
                    expression: "currentLabel"
                  }
                }),
                _c("v-spacer")
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        transition: "dialog-bottom-transition",
        scrollable: ""
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attr = _ref.attr
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.initialize
                      }
                    },
                    "v-btn",
                    attr,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-plus")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.thg.ThgBatchSelectDialog.title"))
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.disabled
                  },
                  on: {
                    click: _vm.emitSelected
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.thg.ThgBatchSelectDialog.btnText")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c("paginated-table", {
            key: "paginated-table-".concat(_vm.currentType),
            attrs: {
              headers: _vm.headers,
              store: _vm.store,
              showSelect: true,
              selectedItems: _vm.selectedThg,
              loading: _vm.isLoading || _vm.loading
            },
            on: {
              "update:selectedItems": function updateSelectedItems($event) {
                _vm.selectedThg = $event
              },
              "update:selected-items": function updateSelectedItems($event) {
                _vm.selectedThg = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "bodyActions",
                fn: function fn() {
                  return [
                    _c(
                      "span",
                      {
                        staticClass: "mr-1"
                      },
                      [_vm._v(" " + _vm._s(_vm.thgTotalKwhHelper) + " ")]
                    ),
                    _c("v-select", {
                      staticClass: "mb-n3 mt-3",
                      attrs: {
                        disabled: _vm.typeLock,
                        dense: "",
                        items: _vm.types,
                        outlined: "",
                        "persistent-hint": ""
                      },
                      on: {
                        change: _vm.getItems
                      },
                      model: {
                        value: _vm.currentType,
                        callback: function callback($$v) {
                          _vm.currentType = $$v
                        },
                        expression: "currentType"
                      }
                    })
                  ]
                },
                proxy: true
              }
            ])
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: "imageContainerContainer",
      style: "width:100%; height: 100%;"
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.debounceResize,
              expression: "debounceResize"
            }
          ],
          ref: "imageContainer",
          style: "width: ".concat(_vm.width, "; display:block; margin:auto;")
        },
        [
          _c(
            "clipper-basic",
            {
              ref: "clippy",
              attrs: {
                src: _vm.src,
                "touch-create": true
              },
              on: {
                load: _vm.debounceResize
              }
            },
            [
              _c(
                "div",
                {
                  attrs: {
                    slot: "placeholder"
                  },
                  slot: "placeholder"
                },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: {
                        height: "100vh"
                      },
                      attrs: {
                        justify: "center",
                        align: "center"
                      }
                    },
                    [
                      _c("latest-entries-card-empty", {
                        staticClass: "ma-auto"
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ]
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.loading
    ? _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4"
            },
            [
              _c(
                "h3",
                {
                  staticClass: "text-truncate"
                },
                [_vm._v("Affiliates")]
              ),
              _c("affiliate-create-dialog")
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "flex-container",
              attrs: {
                flat: ""
              }
            },
            [
              _c("filter-card", {
                attrs: {
                  search: _vm.search,
                  filterList: _vm.filterList,
                  filterItems: _vm.affiliates,
                  filteredItems: _vm.filteredItems
                },
                on: {
                  "update:search": function updateSearch($event) {
                    _vm.search = $event
                  }
                }
              }),
              _c(
                "div",
                {
                  staticClass: "scrollable"
                },
                [
                  _vm._l(_vm.filteredItems, function(item) {
                    return _c(
                      "v-list",
                      {
                        key: item.id,
                        attrs: {
                          "two-line": "",
                          "item-height": 65
                        }
                      },
                      [
                        _c(
                          "v-list-item",
                          {
                            key: item.id,
                            class: _vm.isActive(item),
                            on: {
                              click: function click($event) {
                                return _vm.set(item)
                              }
                            }
                          },
                          [
                            _c(
                              "v-list-item-content",
                              [
                                _c("v-list-item-title", [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(_vm.affiliateType(item.type).type)
                                    ) + " "
                                  )
                                ]),
                                _c("v-list-item-subtitle", {
                                  domProps: {
                                    innerHTML: _vm._s(item.code)
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "v-list-item-action",
                              [
                                _c("v-list-item-action-text", [
                                  _vm._v(_vm._s(_vm.date(item)))
                                ]),
                                _c("v-icon", {
                                  attrs: {
                                    color: "grey lighten-1"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  }),
                  _vm.filteredItems.length == 0
                    ? _c(
                        "div",
                        {
                          staticClass: "emptyDataContainer"
                        },
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: {
                                top: "",
                                "nudge-right": ""
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function fn(_ref) {
                                      var on = _ref.on
                                      return [
                                        _c(
                                          "v-img",
                                          _vm._g(
                                            {
                                              attrs: {
                                                src: _vm.emptyDataSrc,
                                                height: "100",
                                                width: "200",
                                                contain: ""
                                              }
                                            },
                                            on
                                          )
                                        )
                                      ]
                                    }
                                  }
                                ],
                                null,
                                false,
                                974937243
                              )
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.partner.PartnerReportList.noData"
                                    )
                                  )
                                )
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    : _c("v-skeleton-loader", {
        attrs: {
          type:
            "article, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line, list-item-two-line"
        }
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
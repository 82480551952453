var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ml-3",
      attrs: {
        outlined: "",
        width: _vm.width,
        title: _vm.$t("components.thg.thgBillingSelectionBatchData.title"),
        margin: 0
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              !_vm.loading && !_vm.batchData
                ? _c(
                    "v-icon",
                    {
                      staticClass: "billingAlert",
                      attrs: {
                        color: "red"
                      }
                    },
                    [_vm._v("mdi-alert-box")]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-actions",
        [
          !_vm.loading &&
          _vm.batchData &&
          _vm.batchData.pricePerKwh &&
          _vm.batchData.name
            ? _c(
                "v-form",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionBatchData.commission"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.batchData.pricePerKwh.commission,
                      callback: function callback($$v) {
                        _vm.$set(_vm.batchData.pricePerKwh, "commission", $$v)
                      },
                      expression: "batchData.pricePerKwh.commission"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionBatchData.unit"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.batchData.pricePerKwh.unit,
                      callback: function callback($$v) {
                        _vm.$set(_vm.batchData.pricePerKwh, "unit", $$v)
                      },
                      expression: "batchData.pricePerKwh.unit"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionBatchData.value"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.batchData.pricePerKwh.value,
                      callback: function callback($$v) {
                        _vm.$set(_vm.batchData.pricePerKwh, "value", $$v)
                      },
                      expression: "batchData.pricePerKwh.value"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionBatchData.name"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: _vm.locked
                    },
                    model: {
                      value: _vm.batchData.name,
                      callback: function callback($$v) {
                        _vm.$set(_vm.batchData, "name", $$v)
                      },
                      expression: "batchData.name"
                    }
                  }),
                  _c("debug", {
                    attrs: {
                      debug: _vm.batchData
                    }
                  })
                ],
                1
              )
            : !_vm.loading
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [_c("latest-entries-card-empty")],
                1
              )
            : _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type:
                    "list-item-two-line, list-item-two-line, list-item-two-line"
                }
              })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-autocomplete", {
    staticStyle: {
      width: "100%"
    },
    attrs: {
      loading: "loadingAllUsers",
      items: _vm.allUsers,
      filter: _vm.filter,
      label: _vm.$t("components.thg.thgBillingCsvSelection.user"),
      rules: _vm.rules,
      "item-value": "_id",
      "item-text": "userName",
      outlined: "",
      clearable: ""
    },
    model: {
      value: _vm.selected,
      callback: function callback($$v) {
        _vm.selected = $$v
      },
      expression: "selected"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: _vm.$t("navigation.ThePartnerList.setting.billingList"),
      filterList: _vm.filterList,
      loading: _vm.loading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c("v-select", {
              staticClass: "mt-n8",
              staticStyle: {
                width: "80px",
                position: "relative",
                top: "30px"
              },
              attrs: {
                items: _vm.billingTypes,
                outlined: "",
                rounded: "",
                dense: ""
              },
              on: {
                change: _vm.updateBillingType
              },
              scopedSlots: _vm._u([
                {
                  key: "selection",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _c("small", [
                        _vm._v(_vm._s(_vm.getBillingTypeShortened(item)))
                      ])
                    ]
                  }
                },
                {
                  key: "item",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _vm._v(" " + _vm._s(_vm.getBillingTypeText(item)) + " ")
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.billingType,
                callback: function callback($$v) {
                  _vm.billingType = $$v
                },
                expression: "billingType"
              }
            })
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref3) {
          var listItem = _ref3.listItem
          return [
            listItem
              ? _c(
                  "v-list-item",
                  {
                    key: listItem.id,
                    class: _vm.isActive(listItem),
                    attrs: {
                      disabled: _vm.isLoadingDetail,
                      "two-line": ""
                    },
                    on: {
                      click: function click($event) {
                        return _vm.set(listItem)
                      }
                    }
                  },
                  [
                    _c(
                      "v-list-item-content",
                      [
                        _c("v-list-item-title", [
                          _vm._v(_vm._s(listItem.billingNumber))
                        ]),
                        _c("v-list-item-subtitle", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getBillingTypeText(listItem.type)) +
                              " "
                          )
                        ])
                      ],
                      1
                    ),
                    _c(
                      "v-list-item-action",
                      [
                        _c("v-list-item-action-text", [
                          _vm._v(_vm._s(_vm.date(listItem)))
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.hasPem
    ? _c(
        "v-card",
        {
          staticClass: "mb-10 mx-2",
          attrs: {
            outlined: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c("v-text-field", {
                attrs: {
                  dense: "",
                  "append-icon": "mdi-magnify",
                  label: _vm.$t(
                    "components.analytics.AnalyticsDataTableDialog.search"
                  ),
                  "single-line": "",
                  "hide-details": "",
                  solo: ""
                },
                model: {
                  value: _vm.search,
                  callback: function callback($$v) {
                    _vm.search = $$v
                  },
                  expression: "search"
                }
              }),
              _c("v-spacer"),
              _c("thg-customer-data-analytics-export-dialog", {
                attrs: {
                  customerAnalyticsData: _vm.customerData,
                  titleStyle: _vm.partnerColor.gradientStyle,
                  titleTextStyle: "color:white",
                  btnColor: _vm.partnerColor.primaryDarken_2
                }
              })
            ],
            1
          ),
          _c("v-divider"),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              loading: _vm.isLoading,
              items: _vm.customerData,
              search: _vm.search
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.insurance",
                  fn: function fn(_ref) {
                    var item = _ref.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.insurance(item.insurance)))
                      ])
                    ]
                  }
                },
                {
                  key: "item.insuranceNumber",
                  fn: function fn(_ref2) {
                    var item = _ref2.item
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.insuranceNumber(item.insurance)))
                      ])
                    ]
                  }
                },
                {
                  key: "item.numberOfThgs",
                  fn: function fn(_ref3) {
                    var item = _ref3.item
                    return [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            bottom: ""
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(_ref4) {
                                  var on = _ref4.on,
                                    attrs = _ref4.attrs
                                  return [
                                    _c(
                                      "span",
                                      _vm._g(
                                        _vm._b({}, "span", attrs, false),
                                        on
                                      ),
                                      [
                                        _vm._v(
                                          _vm._s(_vm.numberOfThgs(item.thg))
                                        )
                                      ]
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            true
                          )
                        },
                        [
                          _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgAnalyticsCustomerDataTableCard.hint",
                                    {
                                      username: item.user.userName,
                                      amount: _vm.numberOfThgs(item.thg)
                                    }
                                  )
                                ) +
                                " "
                            )
                          ])
                        ]
                      )
                    ]
                  }
                },
                {
                  key: "item.amountMeterReading",
                  fn: function fn(_ref5) {
                    var item = _ref5.item
                    return [
                      _vm._v(
                        " " +
                          _vm._s(_vm.amountMeterReadings(item.meterReading)) +
                          " "
                      )
                    ]
                  }
                }
              ],
              null,
              true
            )
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-tabs",
        {
          model: {
            value: _vm.tab,
            callback: function callback($$v) {
              _vm.tab = $$v
            },
            expression: "tab"
          }
        },
        [
          _c("v-tab", [_vm._v(_vm._s(_vm.$t("thg.PriceTable.title")) + " ")]),
          _c("v-tab", [
            _vm._v(_vm._s(_vm.$t("thg.PromotionTable.title")) + " ")
          ]),
          _c("v-tab-item", [_c("thg-price-table")], 1),
          _c("v-tab-item", [_c("thg-promotion-table")], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
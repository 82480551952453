import { render, staticRenderFns } from "./ThgBillingSelectionCreditItem.vue?vue&type=template&id=cc066bd8&"
import script from "./ThgBillingSelectionCreditItem.vue?vue&type=script&lang=ts&"
export * from "./ThgBillingSelectionCreditItem.vue?vue&type=script&lang=ts&"
import style0 from "./ThgBillingSelectionCreditItem.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('cc066bd8')) {
      api.createRecord('cc066bd8', component.options)
    } else {
      api.reload('cc066bd8', component.options)
    }
    module.hot.accept("./ThgBillingSelectionCreditItem.vue?vue&type=template&id=cc066bd8&", function () {
      api.rerender('cc066bd8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgBillingSelectionCreditItem.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-btn",
        {
          staticClass: "ml-2",
          attrs: {
            elevation: "0",
            color: "info"
          },
          on: {
            click: _vm.initialize
          }
        },
        [_vm._v(" Auswahlhilfe ")]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialog,
            rightDisabled: !_vm.isFormValid,
            title: _vm.$t(
              "components.thg.ThgBatchUpdateItemsByVinDialog.title"
            ),
            supressKeyboardCancel: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialog = false
            },
            rightClick: _vm.seeResults
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.thg.ThgBatchUpdateItemsByVinDialog.body")
              ) +
              " "
          ),
          _c(
            "v-form",
            {
              model: {
                value: _vm.isFormValid,
                callback: function callback($$v) {
                  _vm.isFormValid = $$v
                },
                expression: "isFormValid"
              }
            },
            [
              _c("v-text-field", {
                attrs: {
                  "single-line": false,
                  rules: _vm.required,
                  outlined: "",
                  rounded: ""
                },
                model: {
                  value: _vm.vinListUnparsed,
                  callback: function callback($$v) {
                    _vm.vinListUnparsed = $$v
                  },
                  expression: "vinListUnparsed"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.resultDialog,
            leftText: _vm.$t(
              "components.thg.ThgBatchUpdateItemsByVinDialog.back"
            ),
            title: _vm.$t(
              "components.thg.ThgBatchUpdateItemsByVinDialog.resultsTitle"
            )
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.resultDialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.resultDialog = $event
            },
            leftClick: function leftClick() {
              _vm.resultDialog = false
              _vm.dialog = true
            },
            rightClick: _vm.confirm
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.thg.ThgBatchUpdateItemsByVinDialog.found")
              ) +
              " "
          ),
          _vm.foundThgs.length > 0
            ? _c(
                "div",
                _vm._l(_vm.foundThgs, function(thg) {
                  return _c(
                    "v-list-item",
                    {
                      key: thg.id,
                      attrs: {
                        "three-line": ""
                      }
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [_vm._v(_vm._s(thg.id))]),
                          thg.numberplate
                            ? _c("v-list-item-subtitle", [
                                _vm._v(" " + _vm._s(thg.numberplate) + " ")
                              ])
                            : _vm._e(),
                          thg.registration.identificationnumber
                            ? _c("v-list-item-subtitle", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      thg.registration.identificationnumber
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c("v-list-item", [_c("v-list-item-title", [_vm._v("-")])], 1),
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("components.thg.ThgBatchUpdateItemsByVinDialog.notFound")
              ) +
              " "
          ),
          _vm.notFoundVins.length > 0
            ? _c(
                "div",
                _vm._l(_vm.notFoundVins, function(vin) {
                  return _c(
                    "v-list-item",
                    {
                      key: vin
                    },
                    [
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v(_vm._s(vin))])],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            : _c("v-list-item", [_c("v-list-item-title", [_vm._v("-")])], 1)
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "d-inline"
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            loading: _vm.isLoading,
            icon: ""
          },
          on: {
            click: function click($event) {
              _vm.isDialogActive = true
            }
          }
        },
        [_c("v-icon", [_vm._v("mdi-key-change")])],
        1
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            "max-width": "800",
            isDialogActive: _vm.isDialogActive,
            fullscreen: _vm.$vuetify.breakpoint.xsOnly,
            title: _vm.$t(_vm.title),
            subtitle: _vm.subTitle,
            hideRight: true,
            leftText: _vm.$t(_vm.btnText),
            leftColor: "warning",
            leftLoading: _vm.isLoading,
            leftDisabled: !_vm.userId
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isDialogActive = $event
            },
            leftClick: _vm.transfer
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              outlined: "",
              rules: _vm.requiredRules,
              label: "UserId"
            },
            model: {
              value: _vm.userId,
              callback: function callback($$v) {
                _vm.userId = $$v
              },
              expression: "userId"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
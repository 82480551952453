var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("template-dialog", {
    attrs: {
      context: _vm.context,
      to: _vm.to,
      from: [_vm.partner]
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      ref: "outerContainer",
      staticStyle: {
        height: "100%",
        width: "100%"
      }
    },
    [
      _vm.isImages
        ? _c("canvas", {
            ref: "canvas",
            staticStyle: {
              display: "none"
            }
          })
        : _vm._e(),
      _vm.isImages
        ? _c(
            "v-row",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.cropIt,
                  expression: "cropIt"
                }
              ],
              staticStyle: {
                height: "100%",
                width: "100%"
              },
              attrs: {
                align: "center",
                justify: "center"
              }
            },
            [
              _c(
                "div",
                {
                  ref: "container",
                  staticClass: "ma-auto",
                  style: "transform: ".concat(_vm.transformation, "; ")
                },
                [
                  _c("v-progress-circular", {
                    attrs: {
                      indeterminate: ""
                    }
                  })
                ],
                1
              )
            ]
          )
        : _c(
            "v-row",
            {
              staticStyle: {
                height: "100%",
                width: "100%"
              },
              attrs: {
                align: "center",
                justify: "center"
              }
            },
            [_c("latest-entries-card-empty")],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        absolute: true,
        width: "800px",
        height: "calc(100vh - 64px)"
      },
      on: {
        close: function close($event) {
          return _vm.$emit("input", null)
        }
      }
    },
    [
      _c("update-form", {
        attrs: {
          value: _vm.value,
          dto: _vm.dto
        },
        scopedSlots: _vm._u(
          [
            {
              key: "extraPanels",
              fn: function fn(props) {
                return [_vm._t("extraPanels", null, null, props)]
              }
            }
          ],
          null,
          true
        )
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./EfahrerImage.vue?vue&type=template&id=040e4cc8&"
import script from "./EfahrerImage.vue?vue&type=script&lang=ts&"
export * from "./EfahrerImage.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('040e4cc8')) {
      api.createRecord('040e4cc8', component.options)
    } else {
      api.reload('040e4cc8', component.options)
    }
    module.hot.accept("./EfahrerImage.vue?vue&type=template&id=040e4cc8&", function () {
      api.rerender('040e4cc8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/efahrer/EfahrerImage.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "ma-4",
      attrs: {
        flat: ""
      }
    },
    [
      !_vm.loading
        ? [
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    staticStyle: {
                      "z-index": "1"
                    },
                    attrs: {
                      sm: "4",
                      md: "3",
                      cols: "12"
                    }
                  },
                  [
                    _c(
                      "h3",
                      {
                        staticClass: "text-truncate"
                      },
                      [
                        _vm._v(
                          _vm._s(_vm.selectedThg.numberplate) +
                            ", Datum: " +
                            _vm._s(_vm.date)
                        )
                      ]
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "text-truncate"
                      },
                      [
                        _vm._v(" " + _vm._s(_vm.selectedThg.year) + " "),
                        _vm.selectedThg.isMultiyear
                          ? _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-numeric-2-box-multiple")]
                            )
                          : _c(
                              "v-icon",
                              {
                                attrs: {
                                  small: ""
                                }
                              },
                              [_vm._v("mdi-numeric-1-box-multiple")]
                            )
                      ],
                      1
                    ),
                    _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.partner.companyName) +
                          " " +
                          _vm._s(
                            _vm._f("getFlagEmoji")(_vm.partner.countryCode)
                          )
                      )
                    ])
                  ]
                ),
                _vm.selectedUser
                  ? _c(
                      "v-col",
                      {
                        staticStyle: {
                          "z-index": "1"
                        },
                        attrs: {
                          sm: "4",
                          md: "3",
                          cols: "12"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-small": "",
                                  text: ""
                                },
                                on: {
                                  click: _vm.updateName
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-small": ""
                                    }
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _vm._v(
                                  _vm._s(_vm.selectedUser.firstName) +
                                    " " +
                                    _vm._s(_vm.selectedUser.lastName) +
                                    " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-small": "",
                                  text: ""
                                },
                                on: {
                                  click: _vm.copyEmailToClipboard
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-small": ""
                                    }
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedUser.userName) + " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.selectedUser && _vm.selectedUser.company
                  ? _c(
                      "v-col",
                      {
                        staticStyle: {
                          "z-index": "1"
                        },
                        attrs: {
                          sm: "4",
                          md: "3",
                          cols: "12"
                        }
                      },
                      [
                        _c(
                          "div",
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  "x-small": "",
                                  text: ""
                                },
                                on: {
                                  click: _vm.updateCompany
                                }
                              },
                              [
                                _c(
                                  "v-icon",
                                  {
                                    attrs: {
                                      "x-small": ""
                                    }
                                  },
                                  [_vm._v("mdi-content-copy")]
                                ),
                                _vm._v(
                                  " " + _vm._s(_vm.selectedUser.company) + " "
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _vm.selectedThg.parentId
                          ? _c(
                              "div",
                              [
                                _c(
                                  "v-btn",
                                  {
                                    attrs: {
                                      "x-small": "",
                                      text: ""
                                    },
                                    on: {
                                      click: function click() {
                                        return (_vm.isQuickCheckoutDialogActive = true)
                                      }
                                    }
                                  },
                                  [
                                    _c(
                                      "v-icon",
                                      {
                                        attrs: {
                                          "x-small": ""
                                        }
                                      },
                                      [_vm._v("mdi-open-in-app")]
                                    ),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.thg.ThgQuoteQuickCheckoutListDialog.quickCheckouts"
                                          )
                                        ) +
                                        " "
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _vm._e()
                      ]
                    )
                  : _vm._e(),
                _c(
                  "v-col",
                  {
                    staticStyle: {
                      "z-index": "1"
                    },
                    attrs: {
                      sm: "4",
                      md: "3",
                      cols: "12"
                    }
                  },
                  [
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "x-small": "",
                              text: ""
                            },
                            on: {
                              click: _vm.toPartnerHomeView
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "x-small": ""
                                }
                              },
                              [_vm._v("mdi-open-in-new")]
                            ),
                            _vm._v("partner ")
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c(
                          "v-btn",
                          {
                            attrs: {
                              "x-small": "",
                              text: ""
                            },
                            on: {
                              click: _vm.toThgDetailView
                            }
                          },
                          [
                            _c(
                              "v-icon",
                              {
                                attrs: {
                                  "x-small": ""
                                }
                              },
                              [_vm._v("mdi-open-in-new")]
                            ),
                            _vm._v("thg ")
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "v-row",
              [
                _c(
                  "v-col",
                  {
                    attrs: {
                      md: "9",
                      cols: "12"
                    }
                  },
                  [
                    _c("thg-crop-image-dialog", {
                      attrs: {
                        selectedThg: _vm.selectedThg,
                        userNotification: _vm.userNotification
                      },
                      on: {
                        "update:userNotification": function updateUserNotification(
                          $event
                        ) {
                          _vm.userNotification = $event
                        },
                        "update:user-notification": function updateUserNotification(
                          $event
                        ) {
                          _vm.userNotification = $event
                        },
                        askForWork: _vm.askForWork
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "additionalActionsTop",
                            fn: function fn() {
                              return [
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          text: ""
                                        },
                                        on: {
                                          click: _vm.toPartnerHomeView
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              "x-small": ""
                                            }
                                          },
                                          [_vm._v("mdi-open-in-new")]
                                        ),
                                        _vm._v("partner ")
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: {
                                          "x-small": "",
                                          text: ""
                                        },
                                        on: {
                                          click: _vm.toThgDetailView
                                        }
                                      },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              "x-small": ""
                                            }
                                          },
                                          [_vm._v("mdi-open-in-new")]
                                        ),
                                        _vm._v("thg ")
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-card",
                                  {
                                    staticClass: "mt-5",
                                    attrs: {
                                      flat: ""
                                    }
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "text-truncate"
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.selectedThg.numberplate) +
                                            ", Datum: " +
                                            _vm._s(_vm.date)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-truncate"
                                      },
                                      [
                                        _vm.selectedThg.isMultiyear
                                          ? _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-numeric-2-box-multiple"
                                                )
                                              ]
                                            )
                                          : _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-numeric-1-box-multiple"
                                                )
                                              ]
                                            ),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.selectedThg.year) +
                                            " "
                                        )
                                      ],
                                      1
                                    ),
                                    _vm.selectedUser
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [_vm._v("mdi-account")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedUser.firstName
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm.selectedUser.lastName
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedUser
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [_vm._v("mdi-mail")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedUser.userName
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.selectedUser && _vm.selectedUser.company
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [_vm._v("mdi-factory")]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.selectedUser.company
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.partner
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: ""
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  "mdi-file-multiple-outline"
                                                )
                                              ]
                                            ),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.partner.companyName
                                                ) +
                                                " " +
                                                _vm._s(
                                                  _vm._f("getFlagEmoji")(
                                                    _vm.partner.countryCode
                                                  )
                                                ) +
                                                " "
                                            )
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "additionalActions",
                            fn: function fn() {
                              return [
                                _c("thg-work-item-rejection-card", {
                                  staticClass: "pr-4 mb-n1",
                                  attrs: {
                                    selectedThg: _vm.selectedThg,
                                    selectedUser: _vm.selectedUser,
                                    userNotification: _vm.userNotification,
                                    newWorkitem: _vm.newWorkitem
                                  },
                                  on: {
                                    "update:userNotification": function updateUserNotification(
                                      $event
                                    ) {
                                      _vm.userNotification = $event
                                    },
                                    "update:user-notification": function updateUserNotification(
                                      $event
                                    ) {
                                      _vm.userNotification = $event
                                    },
                                    "update:newWorkitem": function updateNewWorkitem(
                                      $event
                                    ) {
                                      _vm.newWorkitem = $event
                                    },
                                    "update:new-workitem": function updateNewWorkitem(
                                      $event
                                    ) {
                                      _vm.newWorkitem = $event
                                    },
                                    askForWork: _vm.askForWork
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        false,
                        781810787
                      )
                    }),
                    _c("thg-quote-workitem-image-card", {
                      attrs: {
                        selectedThg: _vm.selectedThg
                      }
                    }),
                    _c("thg-crop-image-loader")
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    staticStyle: {
                      "z-index": "1"
                    },
                    attrs: {
                      md: "3",
                      cols: "12"
                    }
                  },
                  [
                    _c("thg-quote-workitem-registration-card", {
                      ref: "registrationCard",
                      attrs: {
                        selectedThg: _vm.updatedThg,
                        selectedUser: _vm.selectedUser
                      },
                      on: {
                        submit: _vm.submit
                      }
                    }),
                    _c(
                      "v-card",
                      {
                        staticClass: "mx-auto",
                        attrs: {
                          flat: ""
                        }
                      },
                      [
                        _c(
                          "v-card-actions",
                          [
                            _c("thg-work-item-rejection-card", {
                              attrs: {
                                selectedUser: _vm.selectedUser,
                                selectedThg: _vm.selectedThg,
                                userNotification: _vm.userNotification,
                                newWorkitem: _vm.newWorkitem
                              },
                              on: {
                                "update:userNotification": function updateUserNotification(
                                  $event
                                ) {
                                  _vm.userNotification = $event
                                },
                                "update:user-notification": function updateUserNotification(
                                  $event
                                ) {
                                  _vm.userNotification = $event
                                },
                                "update:newWorkitem": function updateNewWorkitem(
                                  $event
                                ) {
                                  _vm.newWorkitem = $event
                                },
                                "update:new-workitem": function updateNewWorkitem(
                                  $event
                                ) {
                                  _vm.newWorkitem = $event
                                },
                                askForWork: _vm.askForWork
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _c("v-skeleton-loader", {
            staticClass: "mx-auto pt-4",
            attrs: {
              type: "card, article, article"
            }
          }),
      _vm.isQuickCheckoutDialogActive
        ? _c("thg-quote-quick-checkout-list-dialog", {
            attrs: {
              isDialogActive: _vm.isQuickCheckoutDialogActive,
              parentId: _vm.selectedThg.parentId,
              partnerId: _vm.selectedThg.partnerId
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isQuickCheckoutDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isQuickCheckoutDialogActive = $event
              },
              close: function close($event) {
                _vm.isQuickCheckoutDialogActive = false
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
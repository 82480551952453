var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      attrs: {
        outlined: "",
        width: _vm.width,
        title: _vm.$t(
          "components.thg.thgBillingSelectionItemBillingResult.title"
        ),
        margin: 0
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _vm.billing && _vm.billing.userId
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref) {
                              var on = _ref.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled: _vm.locked
                                      },
                                      on: {
                                        click: function click($event) {
                                          _vm.publishDialog = true
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-email-outline")])],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        728031480
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemBillingResult.publishDialogButton"
                            )
                          ) + " "
                        )
                      ])
                    ]
                  )
                : _vm._e(),
              _vm.billing && _vm.billing.userId
                ? _c(
                    "v-tooltip",
                    {
                      attrs: {
                        top: "",
                        "nudge-right": ""
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function fn(_ref2) {
                              var on = _ref2.on
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    {
                                      staticClass: "mr-4",
                                      attrs: {
                                        icon: ""
                                      },
                                      on: {
                                        click: function click($event) {
                                          _vm.locked = !_vm.locked
                                        }
                                      }
                                    },
                                    on
                                  ),
                                  [
                                    _c("v-icon", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.locked
                                            ? "mdi-lock-outline"
                                            : "mdi-lock-open"
                                        )
                                      )
                                    ])
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1644607086
                      )
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionBatchData.unlock"
                            )
                          ) + " "
                        )
                      ])
                    ]
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card-actions",
        [
          _vm.billing
            ? _c(
                "v-form",
                {
                  staticStyle: {
                    width: "100%"
                  }
                },
                [
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.billingNumber"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: ""
                    },
                    model: {
                      value: _vm.billing.billingNumber,
                      callback: function callback($$v) {
                        _vm.$set(_vm.billing, "billingNumber", $$v)
                      },
                      expression: "billing.billingNumber"
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      value: _vm.$t(
                        "components.thg.thgBillingCreateNewBillingManual.".concat(
                          _vm.billing.type
                        )
                      ),
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.type"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: ""
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      value: _vm.billing.isPublished,
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.isPublished"
                      ),
                      outlined: "",
                      disabled: "",
                      dense: "",
                      flat: ""
                    }
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      value: _vm.billing.userId,
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.userId"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "append",
                          fn: function fn() {
                            return [
                              _vm.billing.userId
                                ? _c(
                                    "v-icon",
                                    {
                                      on: {
                                        click: _vm.goToUserDetail
                                      }
                                    },
                                    [_vm._v(" mdi-open-in-new ")]
                                  )
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3493841632
                    )
                  }),
                  _c("v-text-field", {
                    staticClass: "mb-n2",
                    attrs: {
                      value: _vm.billing.accountNumber,
                      label: _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.accountNumber"
                      ),
                      outlined: "",
                      dense: "",
                      flat: "",
                      disabled: ""
                    }
                  }),
                  _vm.billing.thgId
                    ? _c("v-text-field", {
                        staticClass: "mb-n2",
                        attrs: {
                          value: _vm.billing.thgId,
                          label: _vm.$t(
                            "components.thg.thgBillingSelectionItemBillingResult.thgId"
                          ),
                          outlined: "",
                          dense: "",
                          flat: "",
                          disabled: ""
                        }
                      })
                    : _vm._e(),
                  _vm._l(_vm.billing.referenceIds, function(
                    referenceId,
                    index
                  ) {
                    return _c(
                      "div",
                      {
                        key: referenceId
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-n2",
                          attrs: {
                            value: referenceId,
                            label: ""
                              .concat(
                                _vm.$t(
                                  "components.thg.thgBillingSelectionItemBillingResult.thgId"
                                ),
                                " #"
                              )
                              .concat(index),
                            outlined: "",
                            dense: "",
                            flat: "",
                            disabled: ""
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            : _c("latest-entries-card-empty")
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600"
          },
          on: {
            keydown: function keydown($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.publish.apply(null, arguments)
            }
          },
          model: {
            value: _vm.publishDialog,
            callback: function callback($$v) {
              _vm.publishDialog = $$v
            },
            expression: "publishDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.publishDialog"
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "ml-7 mr-7"
                },
                [
                  _c("v-switch", {
                    attrs: {
                      label: ""
                        .concat(
                          _vm.$t(
                            "components.thg.thgBillingSelectionItemBillingResult.setPublish"
                          ),
                          ": "
                        )
                        .concat(_vm.isReadyForBilling.toString())
                    },
                    model: {
                      value: _vm.isReadyForBilling,
                      callback: function callback($$v) {
                        _vm.isReadyForBilling = $$v
                      },
                      expression: "isReadyForBilling"
                    }
                  }),
                  _vm.isReadyForBilling
                    ? _c("v-switch", {
                        attrs: {
                          label: ""
                            .concat(
                              _vm.$t(
                                "components.thg.thgBillingSelectionItemBillingResult.setNotifyUsers"
                              ),
                              ": "
                            )
                            .concat(_vm.isNotifyUsers.toString())
                        },
                        model: {
                          value: _vm.isNotifyUsers,
                          callback: function callback($$v) {
                            _vm.isNotifyUsers = $$v
                          },
                          expression: "isNotifyUsers"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.publishDialog = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingSelectionItemBillingResult.abort"
                          )
                        )
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: _vm.publish
                      }
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingSelectionItemBillingResult.publish"
                          )
                        )
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: "Advertisments",
      filterList: _vm.filterList,
      loading: _vm.loading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: "",
                  "nudge-right": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function fn(_ref) {
                      var on = _ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.$router.push({
                                    name: "ThgAdvertisementCreate"
                                  })
                                }
                              }
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-plus")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Neue Ad anlegen")])]
            )
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref2) {
          var listItem = _ref2.listItem
          return [
            _c(
              "v-list-item",
              {
                key: listItem ? listItem.id : "",
                class: listItem,
                attrs: {
                  "two-line": "",
                  disabled: _vm.isLoadingDetail
                },
                on: {
                  click: function click($event) {
                    return _vm.set(listItem)
                  }
                }
              },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        _vm._s(
                          listItem.content.text || listItem.content.targetUrl
                        )
                      )
                    ]),
                    _c(
                      "v-list-item-subtitle",
                      {
                        class: _vm.subTitleClass(listItem)
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              listItem.currentlyUsedBudget.toLocaleString(
                                "de-DE",
                                {
                                  style: "currency",
                                  currency: "EUR"
                                }
                              ) +
                                "/" +
                                listItem.monthlyBudget.toLocaleString("de-DE", {
                                  style: "currency",
                                  currency: "EUR"
                                })
                            ) +
                            " "
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "v-list-item-action",
                  [
                    _c("v-list-item-action-text", [
                      _vm._v(
                        " " + _vm._s(listItem.isActive ? "✅" : "⛔️") + " "
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import { render, staticRenderFns } from "./ThgAnalytics.vue?vue&type=template&id=c22af246&scoped=true&"
import script from "./ThgAnalytics.vue?vue&type=script&lang=ts&"
export * from "./ThgAnalytics.vue?vue&type=script&lang=ts&"
import style0 from "./ThgAnalytics.vue?vue&type=style&index=0&id=c22af246&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c22af246",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCol,VRow,VTab,VTabItem,VTabs,VTabsItems})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('c22af246')) {
      api.createRecord('c22af246', component.options)
    } else {
      api.reload('c22af246', component.options)
    }
    module.hot.accept("./ThgAnalytics.vue?vue&type=template&id=c22af246&scoped=true&", function () {
      api.rerender('c22af246', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/thgPortal/ThgAnalytics.vue"
export default component.exports
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.horizontal
        ? _c(
            "div",
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function fn(_ref) {
                          var on = _ref.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    text: "",
                                    block: "",
                                    outlined: "",
                                    large: ""
                                  },
                                  on: {
                                    click: _vm.unclip
                                  }
                                },
                                on
                              ),
                              [_vm._v("Ganzes Bild 👌")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    3232725998
                  )
                },
                [_c("span", [_vm._v("Enter")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function fn(_ref2) {
                          var on = _ref2.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    text: "",
                                    block: "",
                                    outlined: "",
                                    large: ""
                                  },
                                  on: {
                                    click: _vm.reclip
                                  }
                                },
                                on
                              ),
                              [_vm._v("Wie eben 😌")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1250643506
                  )
                },
                [_c("span", [_vm._v("CTRL + Enter")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function fn(_ref3) {
                          var on = _ref3.on
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  staticClass: "ma-1",
                                  attrs: {
                                    block: "",
                                    outlined: "",
                                    large: "",
                                    color: "success"
                                  },
                                  on: {
                                    click: _vm.clip
                                  }
                                },
                                on
                              ),
                              [_vm._v("Clippit! 📸")]
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    1891768127
                  )
                },
                [_c("span", [_vm._v("Space")])]
              )
            ],
            1
          )
        : _c(
            "v-btn-toggle",
            {
              attrs: {
                rounded: ""
              }
            },
            [
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref4) {
                        var on = _ref4.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ma-1",
                                attrs: {
                                  text: "",
                                  outlined: "",
                                  large: ""
                                },
                                on: {
                                  click: _vm.unclip
                                }
                              },
                              on
                            ),
                            [_vm._v("Ganzes Bild 👌")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Enter")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref5) {
                        var on = _ref5.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ma-1",
                                attrs: {
                                  text: "",
                                  outlined: "",
                                  large: ""
                                },
                                on: {
                                  click: _vm.reclip
                                }
                              },
                              on
                            ),
                            [_vm._v("Wie eben 😌")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("CTRL + Enter")])]
              ),
              _c(
                "v-tooltip",
                {
                  attrs: {
                    top: "",
                    "nudge-right": ""
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function fn(_ref6) {
                        var on = _ref6.on
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "ma-1",
                                attrs: {
                                  outlined: "",
                                  large: "",
                                  color: "success"
                                },
                                on: {
                                  click: _vm.clip
                                }
                              },
                              on
                            ),
                            [_vm._v("Clippit! 📸")]
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v("Space")])]
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: _vm.$t("components.thg.thgAdvertismentDetail.title")
      }
    },
    [
      _c("thg-advertisemt-detail-information-card", {
        attrs: {
          loading: _vm.loading
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
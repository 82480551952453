var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      ref: "registrationForm",
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c("v-text-field", {
        ref: "startTextField",
        attrs: {
          label: _vm.$t("components.thg.ThgRegistrationForm.name"),
          rules: _vm.requiredRules,
          outlined: ""
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function fn() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function fn(_ref) {
                          var on = _ref.on
                          return [
                            _c("v-icon", _vm._g({}, on), [
                              _vm._v(" mdi-help-circle-outline ")
                            ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgRegistrationForm.tooltip.name"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.registration.name,
          callback: function callback($$v) {
            _vm.$set(_vm.registration, "name", $$v)
          },
          expression: "registration.name"
        }
      }),
      _c("v-text-field", {
        attrs: {
          rules: _vm.noNumberRules,
          label: _vm.$t("components.thg.ThgRegistrationForm.firstname"),
          outlined: ""
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function fn() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function fn(_ref2) {
                          var on = _ref2.on
                          return [
                            _c("v-icon", _vm._g({}, on), [
                              _vm._v(" mdi-help-circle-outline ")
                            ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgRegistrationForm.tooltip.firstname"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.registration.firstname,
          callback: function callback($$v) {
            _vm.$set(_vm.registration, "firstname", $$v)
          },
          expression: "registration.firstname"
        }
      }),
      _c(
        "v-row",
        {
          staticClass: "ma-0"
        },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 pr-4",
              attrs: {
                cols: "4"
              }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  "auto-select-first": "",
                  items: _vm.day,
                  rules: _vm.requiredRules,
                  label: _vm.$t(
                    "components.thg.ThgRegistrationForm.firstregistrationDay"
                  ),
                  "persistent-hint": "",
                  outlined: "",
                  autocomplete: ""
                },
                on: {
                  blur: function blur($event) {
                    _vm.registration.firstregistrationDay = $event.target.value
                  }
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function fn() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function fn(_ref3) {
                                  var on = _ref3.on
                                  return [
                                    _c("v-icon", _vm._g({}, on), [
                                      _vm._v(" mdi-help-circle-outline ")
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgRegistrationForm.tooltip.firstregistration"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.registration.firstregistrationDay,
                  callback: function callback($$v) {
                    _vm.$set(_vm.registration, "firstregistrationDay", $$v)
                  },
                  expression: "registration.firstregistrationDay"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-0 pr-4",
              attrs: {
                cols: "4"
              }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  "auto-select-first": "",
                  rules: _vm.requiredRules,
                  label: _vm.$t(
                    "components.thg.ThgRegistrationForm.firstregistrationMonth"
                  ),
                  items: _vm.month,
                  "persistent-hint": "",
                  outlined: ""
                },
                on: {
                  blur: function blur($event) {
                    _vm.registration.firstregistrationMonth =
                      $event.target.value
                  }
                },
                model: {
                  value: _vm.registration.firstregistrationMonth,
                  callback: function callback($$v) {
                    _vm.$set(_vm.registration, "firstregistrationMonth", $$v)
                  },
                  expression: "registration.firstregistrationMonth"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-0",
              attrs: {
                cols: "4"
              }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  "auto-select-first": "",
                  rules: _vm.requiredRules,
                  label: _vm.$t(
                    "components.thg.ThgRegistrationForm.firstregistrationYear"
                  ),
                  items: _vm.year,
                  "persistent-hint": "",
                  outlined: ""
                },
                on: {
                  blur: function blur($event) {
                    _vm.registration.firstregistrationYear = $event.target.value
                  }
                },
                model: {
                  value: _vm.registration.firstregistrationYear,
                  callback: function callback($$v) {
                    _vm.$set(_vm.registration, "firstregistrationYear", $$v)
                  },
                  expression: "registration.firstregistrationYear"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "ma-0"
        },
        [
          _c(
            "v-col",
            {
              staticClass: "pa-0 pr-4",
              attrs: {
                cols: "8"
              }
            },
            [
              _c("v-text-field", {
                ref: "fin",
                staticClass: "code",
                attrs: {
                  label: _vm.$t(
                    "components.thg.ThgRegistrationForm.identificationnumber"
                  ),
                  counter: "17",
                  rules: _vm.vinRules,
                  suffix: _vm.getChecksumText,
                  outlined: ""
                },
                on: {
                  blur: _vm.checkfin,
                  keyup: _vm.uppercase
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function fn() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function fn(_ref4) {
                                  var on = _ref4.on
                                  return [
                                    _c("v-icon", _vm._g({}, on), [
                                      _vm._v(" mdi-help-circle-outline ")
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgRegistrationForm.tooltip.identificationnumber"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.registration.identificationnumber,
                  callback: function callback($$v) {
                    _vm.$set(_vm.registration, "identificationnumber", $$v)
                  },
                  expression: "registration.identificationnumber"
                }
              })
            ],
            1
          ),
          _c(
            "v-col",
            {
              staticClass: "pa-0",
              attrs: {
                cols: "4"
              }
            },
            [
              _c("v-autocomplete", {
                attrs: {
                  items: _vm.legitChecksums,
                  rules: _vm.requiredRules,
                  label: _vm.$t("components.thg.ThgRegistrationForm.checksum"),
                  "persistent-hint": "",
                  outlined: "",
                  "cache-items": "",
                  "auto-select-first": ""
                },
                on: {
                  blur: _vm.blurChecksum,
                  keyup: _vm.validate
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function fn() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function fn(_ref5) {
                                  var on = _ref5.on
                                  return [
                                    _c("v-icon", _vm._g({}, on), [
                                      _vm._v(" mdi-help-circle-outline ")
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgRegistrationForm.tooltip.checksum"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm._checksum,
                  callback: function callback($$v) {
                    _vm._checksum = $$v
                  },
                  expression: "_checksum"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c("v-autocomplete", {
        attrs: {
          "auto-select-first": "",
          rules: _vm.requiredRules,
          label: _vm.$t("components.thg.ThgRegistrationForm.driveTyp"),
          outlined: "",
          "cache-items": "",
          items: _vm.driveType
        },
        on: {
          blur: function blur($event) {
            _vm.registration.driveTyp = $event.target.value
          }
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function fn() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function fn(_ref6) {
                          var on = _ref6.on
                          return [
                            _c("v-icon", _vm._g({}, on), [
                              _vm._v(" mdi-help-circle-outline ")
                            ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgRegistrationForm.tooltip.driveTyp"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.registration.driveTyp,
          callback: function callback($$v) {
            _vm.$set(_vm.registration, "driveTyp", $$v)
          },
          expression: "registration.driveTyp"
        }
      }),
      _c("v-autocomplete", {
        attrs: {
          "auto-select-first": "",
          label: _vm.$t("components.thg.ThgRegistrationForm.vehicleClass"),
          rules: _vm.requiredRules,
          outlined: "",
          "cache-items": "",
          items: _vm.vehicleClass,
          "item-text": "showText",
          "item-value": "value"
        },
        scopedSlots: _vm._u([
          {
            key: "prepend",
            fn: function fn() {
              return [
                _c(
                  "v-tooltip",
                  {
                    attrs: {
                      bottom: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function fn(_ref7) {
                          var on = _ref7.on
                          return [
                            _c("v-icon", _vm._g({}, on), [
                              _vm._v(" mdi-help-circle-outline ")
                            ])
                          ]
                        }
                      }
                    ])
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.ThgRegistrationForm.tooltip.vehicleClass"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.registration.vehicleClass,
          callback: function callback($$v) {
            _vm.$set(_vm.registration, "vehicleClass", $$v)
          },
          expression: "registration.vehicleClass"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
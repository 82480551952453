var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.disbledNew,
            elevation: "0",
            color: "info"
          },
          on: {
            click: _vm.openDialog
          }
        },
        [_vm._v(" Neuer Antrag ")]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialog,
            width: 600,
            fullscreen: _vm.isMobile,
            title: "Thg-Quoten einstellen",
            rightLoading: _vm.loading,
            leftDisabled: _vm.loading,
            rightDisabled: _vm.disabled,
            rightText: "THG-Quoten einstellen"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialog = false
            },
            rightClick: _vm.quickCheckoutThg
          }
        },
        [
          _c("partner-selection-form", {
            staticClass: "mb-4",
            attrs: {
              loading: _vm.loading,
              partnerId: _vm.partnerId
            },
            on: {
              save: _vm.savePartner,
              change: _vm.changePartner
            }
          }),
          _c("v-checkbox", {
            staticClass: "mx-4",
            attrs: {
              label: "Nutzerbenachrichtigung"
            },
            model: {
              value: _vm.userNotification,
              callback: function callback($$v) {
                _vm.userNotification = $$v
              },
              expression: "userNotification"
            }
          }),
          _vm.years.length < 1
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "list-item-two-line"
                }
              })
            : _c(
                "v-chip-group",
                {
                  attrs: {
                    column: "",
                    multiple: ""
                  },
                  model: {
                    value: _vm.selectedYears,
                    callback: function callback($$v) {
                      _vm.selectedYears = $$v
                    },
                    expression: "selectedYears"
                  }
                },
                _vm._l(_vm.years, function(year) {
                  return _c(
                    "v-chip",
                    {
                      key: year,
                      attrs: {
                        filter: "",
                        outlined: "",
                        value: year
                      }
                    },
                    [_vm._v(" " + _vm._s(year) + " ")]
                  )
                }),
                1
              ),
          _c("v-text-field", {
            attrs: {
              disabled: "",
              label: "Partner",
              value: _vm.partnerId,
              outlined: "",
              "persistent-hint": ""
            }
          }),
          _c("v-text-field", {
            attrs: {
              disabled: "",
              label: "Anzahl THG-Quoten",
              value: _vm.thgs.length,
              outlined: "",
              "persistent-hint": ""
            }
          }),
          _c("v-text-field", {
            attrs: {
              disabled: "",
              label: "Jahre",
              value: _vm.selectedYears,
              outlined: "",
              "persistent-hint": ""
            }
          }),
          _vm._v(
            " " + _vm._s(_vm.counter) + "/" + _vm._s(_vm.thgs.length) + " "
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("the-layout-portal-list", {
    attrs: {
      title: _vm.$t("report.thgChargingStation.title"),
      filterList: _vm.filterList,
      loading: _vm.loading,
      isLoadingMore: _vm.isLoadingMore,
      getModule: _vm.getModule
    },
    on: {
      loadMore: _vm.loadMore,
      refresh: _vm.refresh
    },
    scopedSlots: _vm._u([
      {
        key: "options",
        fn: function fn() {
          return [
            _c(
              "v-tooltip",
              {
                attrs: {
                  bottom: "",
                  "nudge-right": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function fn(_ref) {
                      var on = _ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                icon: ""
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.$router.push({
                                    name: "ThgChargingStationCreateOverviewView"
                                  })
                                }
                              }
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-plus")])],
                          1
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Neue THG-Quote anlegen")])]
            )
          ]
        },
        proxy: true
      },
      {
        key: "listItem",
        fn: function fn(_ref2) {
          var listItem = _ref2.listItem
          return [
            _c(
              "v-list-item",
              {
                key: listItem ? listItem._id : "",
                attrs: {
                  "input-value": _vm.isActive(listItem),
                  "two-line": "",
                  disabled: _vm.isLoadingDetail
                },
                on: {
                  click: function click($event) {
                    return _vm.set(listItem)
                  }
                }
              },
              [
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", [
                      _vm._v(
                        " " +
                          _vm._s(
                            listItem
                              ? listItem.chargingPointOperator
                                ? listItem.chargingPointOperator.name || ""
                                : ""
                              : ""
                          ) +
                          " ( " +
                          _vm._s(listItem ? listItem.name : "") +
                          " ) "
                      )
                    ]),
                    _c("v-list-item-subtitle", [
                      _vm._v(
                        " " +
                          _vm._s(
                            listItem ? listItem.connectionPowerInKwh : "0"
                          ) +
                          "kW | " +
                          _vm._s(
                            listItem
                              ? listItem.address
                                ? listItem.address.street || ""
                                : ""
                              : ""
                          ) +
                          ", " +
                          _vm._s(listItem ? listItem.address.city || "" : "")
                      )
                    ])
                  ],
                  1
                ),
                _c(
                  "v-list-item-action",
                  [
                    _c("v-list-item-action-text", [
                      _vm._v(_vm._s(_vm.date(listItem)))
                    ])
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "data-card",
    {
      attrs: {
        title: _vm.$t(
          "components.thg.thgBillingSelectionContactData.contactData"
        ),
        locked: _vm.locked,
        confirmEdit: _vm.confirmEdit
      },
      on: {
        "update:locked": function updateLocked($event) {
          _vm.locked = $event
        }
      }
    },
    [
      _vm.data
        ? _c(
            "v-form",
            {
              staticStyle: {
                width: "100%"
              }
            },
            [
              _c("v-text-field", {
                staticClass: "mb-n2",
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgBillingSelectionContactData.phone"
                  ),
                  outlined: "",
                  dense: "",
                  flat: "",
                  disabled: _vm.locked
                },
                model: {
                  value: _vm.contact.phone,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contact, "phone", $$v)
                  },
                  expression: "contact.phone"
                }
              }),
              _c("v-text-field", {
                staticClass: "mb-n2",
                attrs: {
                  label: _vm.$t(
                    "components.thg.thgBillingSelectionContactData.email"
                  ),
                  outlined: "",
                  dense: "",
                  flat: "",
                  disabled: _vm.locked
                },
                model: {
                  value: _vm.contact.email,
                  callback: function callback($$v) {
                    _vm.$set(_vm.contact, "email", $$v)
                  },
                  expression: "contact.email"
                }
              }),
              _c("debug", {
                attrs: {
                  debug: _vm.data
                }
              })
            ],
            1
          )
        : _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type: "list-item-two-line, list-item-two-line, list-item-two-line"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
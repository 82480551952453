var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        isDialogActive: _vm.isDialogActiveLocal,
        fullscreen: _vm.fullscreen,
        title: _vm.$t(
          "components.thg.ThgQuoteQuickCheckoutListDialog.quickCheckouts"
        ),
        leftText: _vm.$t("close"),
        rightText: _vm.$t(
          "components.thg.ThgQuoteQuickCheckoutListDialog.nextWorkItem"
        ),
        hideLeft: _vm.handleInvalidClass,
        hideRight: !_vm.handleInvalidClass,
        hideClose: true
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.isDialogActiveLocal = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.isDialogActiveLocal = $event
        },
        leftClick: _vm.close,
        rightClick: _vm.close,
        close: _vm.close
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-n2",
          staticStyle: {
            display: "flex"
          }
        },
        [
          _c("v-subheader", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "components.thg.ThgQuoteQuickCheckoutListDialog.derivedFrom"
                  )
                ) +
                ": "
            )
          ]),
          _vm.isLoadingParent
            ? _c("v-progress-circular", {
                attrs: {
                  "x-small": "",
                  indeterminate: ""
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.parents, function(item) {
        return _c(
          "v-card",
          {
            key: "parent" + item.id,
            staticClass: "my-1",
            attrs: {
              outlined: "",
              elevation: 0
            }
          },
          [
            _c("refs-thg", {
              attrs: {
                item: item
              },
              on: {
                openDetail: function openDetail($event) {
                  return _vm.goToDetail(item)
                }
              }
            }),
            _c(
              "v-sheet",
              {
                attrs: {
                  color: "error",
                  rounded: ""
                }
              },
              [
                _vm.handleInvalidClass &&
                item.status !== _vm.ThgStatusEnum.INVALID_VEHICLE_CLASS &&
                item.status !==
                  _vm.ThgStatusEnum.PAYED_AND_INVALID_VEHICLE_CLASS
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          block: "",
                          elevation: 0,
                          color: "error"
                        },
                        on: {
                          click: function click() {
                            return _vm.changeStatusToPayedAndInvalidVehicleClass(
                              item.id
                            )
                          }
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.ThgQuoteQuickCheckoutListDialog.changeStatusTo",
                                {
                                  status: "PAYED_AND_INVALID_VEHICLE_CLASS"
                                }
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e(),
                _vm.changeStatusToPayedAndInvalidVehicleClassId === item.id
                  ? _c(
                      "div",
                      {
                        staticClass: "mx-2",
                        staticStyle: {
                          display: "flex"
                        }
                      },
                      [
                        _c("v-switch", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function fn() {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "white--text"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.thg.ThgQuoteDetailInformationStatusCard.userNotification"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ],
                            null,
                            true
                          ),
                          model: {
                            value: _vm.notifyUser,
                            callback: function callback($$v) {
                              _vm.notifyUser = $$v
                            },
                            expression: "notifyUser"
                          }
                        }),
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mb-n4 mt-4 white--text",
                            attrs: {
                              loading: _vm.isUpdateStatusLoading,
                              text: "",
                              elevation: 0
                            },
                            on: {
                              click:
                                _vm.updateStatusToPayedAndInvalidVehicleClass
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          ],
          1
        )
      }),
      _c(
        "div",
        {
          staticClass: "mb-n2",
          staticStyle: {
            display: "flex"
          }
        },
        [
          _c("v-subheader", [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t(
                    "components.thg.ThgQuoteQuickCheckoutListDialog.related"
                  )
                ) +
                ": "
            )
          ]),
          _vm.isLoadingSiblings
            ? _c("v-progress-circular", {
                attrs: {
                  "x-small": "",
                  indeterminate: ""
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm._l(_vm.siblings, function(item) {
        return _c(
          "v-card",
          {
            key: "sibling" + item.id,
            staticClass: "my-1",
            attrs: {
              outlined: "",
              elevation: 0
            }
          },
          [
            _c("refs-thg", {
              attrs: {
                item: item
              },
              on: {
                openDetail: function openDetail($event) {
                  return _vm.goToDetail(item)
                }
              }
            })
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
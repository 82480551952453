var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _vm.icon
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            icon: "",
                            disabled: !_vm.value.length
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-key-change")])],
                    1
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            elevation: "0",
                            color: "info",
                            disabled: !_vm.value.length
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgQuoteTransferDialog.action"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      !_vm.isLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("components.thg.ThgQuoteTransferDialog.title")
                      )
                    )
                  ]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          },
                          on: {
                            click: _vm.close
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("components.thg.ThgQuoteTransferDialog.content")
                    ) +
                    " " +
                    _vm._s(_vm.thgIds) +
                    " "
                )
              ]),
              _c(
                "v-radio-group",
                {
                  staticClass: "mx-6",
                  attrs: {
                    row: ""
                  },
                  model: {
                    value: _vm.transferToUser,
                    callback: function callback($$v) {
                      _vm.transferToUser = $$v
                    },
                    expression: "transferToUser"
                  }
                },
                [
                  _c("v-radio", {
                    attrs: {
                      label: "Partner",
                      value: false,
                      "data-test-form-iscompany": ""
                    }
                  }),
                  _c("v-radio", {
                    attrs: {
                      label: "User",
                      value: true,
                      "data-test-form-isprivate": ""
                    }
                  })
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function callback($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  !_vm.transferToUser
                    ? _c("v-autocomplete", {
                        staticClass: "mx-6",
                        attrs: {
                          items: _vm.partners,
                          rules: _vm.requiredRules,
                          "item-text": "companyName",
                          "persistent-hint": "",
                          "return-object": "",
                          outlined: ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function fn(_ref2) {
                                var item = _ref2.item
                                return [
                                  _c(
                                    "v-list-item-content",
                                    {
                                      staticStyle: {
                                        "max-width": "300px"
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v(_vm._s(item.companyName) + " ")
                                      ]),
                                      _c("v-list-item-subtitle", [
                                        _vm._v(
                                          _vm._s(item.companyUsername) +
                                            " (" +
                                            _vm._s(item.id) +
                                            ")"
                                        )
                                      ])
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          2190017332
                        ),
                        model: {
                          value: _vm.newPartner,
                          callback: function callback($$v) {
                            _vm.newPartner = $$v
                          },
                          expression: "newPartner"
                        }
                      })
                    : _c("v-text-field", {
                        staticClass: "mx-6",
                        attrs: {
                          outlined: "",
                          rules: _vm.requiredRules,
                          label: "UserId"
                        },
                        model: {
                          value: _vm.userId,
                          callback: function callback($$v) {
                            _vm.userId = $$v
                          },
                          expression: "userId"
                        }
                      }),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-4",
                          attrs: {
                            color: "warning",
                            disabled: !_vm.valid
                          },
                          on: {
                            click: _vm.transfer
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.thg.ThgQuoteTransferDialog.btnText"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "article, article, actions"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: "",
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attr = _ref.attr
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      staticClass: "mx-2",
                      attrs: {
                        outlined: "",
                        color: "primary"
                      }
                    },
                    "v-btn",
                    attr,
                    false
                  ),
                  on
                ),
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.PartnerBillingInformationDialog.btnText"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4",
              class: _vm.color
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mr-2",
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.partner.PartnerBillingInformationDialog.title",
                          {
                            company: _vm.companyName
                          }
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-row",
            {
              staticClass: "pa-2"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("partner-billing-information-data-card", {
                    attrs: {
                      partnerId: _vm.partnerId,
                      hasPermissions:
                        _vm.$can(
                          _vm.ActionEnum.READ,
                          _vm.ResourceEnum.BILLING_INFORMATION
                        ) &&
                        _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    md: "6"
                  }
                },
                [
                  _c("partner-billing-information-banking-card", {
                    attrs: {
                      partnerId: _vm.partnerId,
                      hasPermissions:
                        _vm.$can(
                          _vm.ActionEnum.READ,
                          _vm.ResourceEnum.PARTNER_BANKING
                        ) &&
                        _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _vm.isPriceInformation
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            elevation: "0",
                            small: "",
                            color: "info",
                            loading: _vm.loading
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.ThgBatchUpdatePriceDialog.btnText"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                : _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            loading: _vm.loading,
                            icon: ""
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          }
                        },
                        [_vm._v("mdi-pencil")]
                      )
                    ],
                    1
                  )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            {
              class: _vm.color
            },
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t("components.thg.ThgBatchUpdatePriceDialog.title")
                  )
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("v-divider"),
          _c(
            "v-card-text",
            {
              staticClass: "mt-6"
            },
            [
              _c("v-autocomplete", {
                staticClass: "mt-2",
                attrs: {
                  type: "text",
                  label: _vm.$t(
                    "components.thg.ThgBatchUpdatePriceDialog.unit"
                  ),
                  rules: _vm.requiredRules,
                  outlined: "",
                  items: _vm.currencyUnits,
                  dense: ""
                },
                model: {
                  value: _vm.unit,
                  callback: function callback($$v) {
                    _vm.unit = $$v
                  },
                  expression: "unit"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  type: "number",
                  label: _vm.$t(
                    "components.thg.ThgBatchUpdatePriceDialog.value"
                  ),
                  rules: _vm.requiredRules,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.value,
                  callback: function callback($$v) {
                    _vm.value = $$v
                  },
                  expression: "value"
                }
              }),
              _c("v-text-field", {
                staticClass: "mt-2",
                attrs: {
                  type: "number",
                  min: "0",
                  max: "100",
                  label: _vm.$t(
                    "components.thg.ThgBatchUpdatePriceDialog.commission"
                  ),
                  rules: _vm.commissionRule,
                  outlined: "",
                  dense: ""
                },
                model: {
                  value: _vm.commission,
                  callback: function callback($$v) {
                    _vm.commission = $$v
                  },
                  expression: "commission"
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            {
              staticClass: "mt-n4"
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-4 mb-4",
                  attrs: {
                    depressed: "",
                    color: "succes"
                  },
                  on: {
                    click: _vm.update
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.thg.ThgBatchUpdatePriceDialog.save")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
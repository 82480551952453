var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        transition: "dialog-bottom-transition",
        "max-width": "450"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      loading: _vm.isLoading,
                      icon: ""
                    },
                    on: {
                      "v-bind": attrs
                    }
                  },
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-table-arrow-down")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "title pt-4"
            },
            [
              _c(
                "h4",
                {
                  staticClass: "text-truncate"
                },
                [
                  _vm._v(
                    _vm._s(_vm.$t("components.thg.ThgQuoteExportDialog.title"))
                  )
                ]
              ),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              )
            ],
            1
          ),
          _c("v-card-text", {
            domProps: {
              innerHTML: _vm._s(
                _vm.$t(
                  "components.thg.ThgCustomerDataAnalyticsExportDialog.description",
                  {
                    from: _vm.from,
                    to: _vm.to
                  }
                )
              )
            }
          }),
          _c(
            "v-radio-group",
            {
              staticClass: "ma-4",
              attrs: {
                column: ""
              },
              model: {
                value: _vm.exportSetting,
                callback: function callback($$v) {
                  _vm.exportSetting = $$v
                },
                expression: "exportSetting"
              }
            },
            [
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.ThgCustomerDataAnalyticsExportDialog.user"
                  ),
                  color: "primary",
                  value: _vm.exportEnum.USER
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.ThgCustomerDataAnalyticsExportDialog.vehicle"
                  ),
                  color: "primary",
                  value: _vm.exportEnum.THG
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.ThgCustomerDataAnalyticsExportDialog.insurance"
                  ),
                  color: "primary",
                  value: _vm.exportEnum.INSURANCE
                }
              }),
              _c("v-radio", {
                attrs: {
                  label: _vm.$t(
                    "components.thg.ThgCustomerDataAnalyticsExportDialog.all"
                  ),
                  color: "primary",
                  value: _vm.exportEnum.ALL
                }
              })
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    color: "primary",
                    outlined: ""
                  },
                  on: {
                    click: _vm.close
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("abort")) + " ")]
              ),
              _c(
                "v-btn",
                {
                  staticClass: "ma-2",
                  attrs: {
                    loading: _vm.isLoading,
                    color: "primary",
                    dark: true
                  },
                  on: {
                    click: _vm.exportData
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.thg.ThgQuoteExportDialog.btnText")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        display: "flex",
        "flex-wrap": "wrap"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "dataContainer flexContainer"
        },
        [
          _vm.thg
            ? _c("thg-billing-selection-item-partner-data", {
                staticClass: "ma-1",
                attrs: {
                  partnerId: _vm.thg.partnerId
                }
              })
            : _vm._e(),
          !_vm.affiliateLoading && _vm.affiliateData
            ? _c("thg-billing-selection-item-affiliate-data", {
                staticClass: "ma-1",
                attrs: {
                  affiliate: _vm.affiliateData
                }
              })
            : _vm._e(),
          !_vm.affiliateLoading && _vm.affiliateData
            ? _c("thg-billing-selection-item-contact-data", {
                staticClass: "ma-1",
                attrs: {
                  userId: _vm.affiliateData.userId
                }
              })
            : _vm._e(),
          !_vm.affiliateLoading && _vm.affiliateData && _vm.affiliateData.userId
            ? _c("thg-billing-selection-item-banking-data", {
                staticClass: "ma-1",
                attrs: {
                  userId: _vm.affiliateData.userId
                }
              })
            : _vm._e()
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
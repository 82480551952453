import { render, staticRenderFns } from "./ThgBatchUpdateSelectedStatusDialog.vue?vue&type=template&id=0000859e&"
import script from "./ThgBatchUpdateSelectedStatusDialog.vue?vue&type=script&lang=ts&"
export * from "./ThgBatchUpdateSelectedStatusDialog.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VForm } from 'vuetify/lib/components/VForm';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VForm})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('0000859e')) {
      api.createRecord('0000859e', component.options)
    } else {
      api.reload('0000859e', component.options)
    }
    module.hot.accept("./ThgBatchUpdateSelectedStatusDialog.vue?vue&type=template&id=0000859e&", function () {
      api.rerender('0000859e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/thg/ThgBatchUpdateSelectedStatusDialog.vue"
export default component.exports
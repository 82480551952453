var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    {
      attrs: {
        title: "report.thgChargingStation.title"
      }
    },
    [
      _vm.initialLoading
        ? _c("v-skeleton-loader", {
            staticClass: "mx-auto",
            attrs: {
              type: "card-heading, image"
            }
          })
        : _c("table-wrapper", {
            attrs: {
              title: _vm.i18n["title"],
              emptyItem: _vm.emptyInsurance,
              confirmNewItems: _vm.confirmNewInsurances,
              headers: _vm.headers,
              allItems: _vm.insurances,
              controlElements: _vm.controlElements,
              showSelect: true,
              selectedItems: _vm.selected
            },
            on: {
              "update:selectedItems": function updateSelectedItems($event) {
                _vm.selected = $event
              },
              "update:selected-items": function updateSelectedItems($event) {
                _vm.selected = $event
              }
            },
            scopedSlots: _vm._u([
              {
                key: "selectActions",
                fn: function fn(_ref) {
                  var selected = _ref.selected
                  return [
                    _c(
                      "v-btn",
                      {
                        staticClass: "ma-1",
                        attrs: {
                          text: "",
                          outlined: "",
                          loading: _vm.isExportLoading
                        },
                        on: {
                          click: function click($event) {
                            return _vm.exportItems(selected)
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(_vm.i18n["export"]) + " ")]
                    )
                  ]
                }
              },
              {
                key: "item.userId",
                fn: function fn(_ref2) {
                  var item = _ref2.item
                  return [
                    _c(
                      "a",
                      {
                        on: {
                          click: function click($event) {
                            return _vm.openUserDetail(item.userId)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.userId))]
                    )
                  ]
                }
              }
            ])
          }),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isDeleteDialogActive,
          width: 600,
          fullscreen: _vm.isMobile,
          title: _vm.i18n["confirmDeleteTitle"],
          rightLoading: _vm.isDeleteInsuranceLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDeleteDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDeleteDialogActive = false
          },
          rightClick: _vm.deleteInsurance
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        icon: ""
                      },
                      on: {
                        click: _vm.initialize
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [_c("v-icon", [_vm._v("mdi-account-cash-outline")])],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      !_vm.isLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v("Auszahlung")]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          },
                          on: {
                            click: _vm.close
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c("v-col", [
                        _c(
                          "div",
                          {
                            staticClass: "text-body-1"
                          },
                          [
                            _c("small", [_vm._v("Kontoinhaber:")]),
                            _vm._v(" " + _vm._s(_vm.selectedBanking.name))
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-body-1"
                          },
                          [
                            _c("small", [_vm._v("IBAN:")]),
                            _vm._v(" " + _vm._s(_vm.selectedBanking.iban))
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "text-body-1"
                          },
                          [
                            _c("small", [_vm._v("Bank:")]),
                            _vm._v(" : " + _vm._s(_vm.selectedBanking.bank))
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _vm.paymentFactor > 0
                    ? _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-text-field", {
                                attrs: {
                                  label: "Prämienhöhe",
                                  type: "number",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.amount,
                                  callback: function callback($$v) {
                                    _vm.amount = $$v
                                  },
                                  expression: "amount"
                                }
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "text-body-1"
                                },
                                [
                                  _vm._v(
                                    "Auszahlung: " + _vm._s(_vm.payment) + " €"
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticClass: "text-body-1 yellow"
                              },
                              [
                                _vm._v(
                                  "Keine Auszahlung, Kunde hat in Nachhaltigkeit investiert!"
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      ),
                  _vm.warning
                    ? _c(
                        "v-row",
                        [
                          _c("v-col", [
                            _c(
                              "div",
                              {
                                staticClass: "text-body-1 red"
                              },
                              [
                                _vm._v(
                                  "Achtung! Bereits gezahlt, Duplikat oder nicht antragsberechtigt!"
                                )
                              ]
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "article, article, actions"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
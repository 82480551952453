var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      !_vm.isWorkStarted
        ? [
            _c(
              "v-row",
              {
                attrs: {
                  align: "center",
                  justify: "center"
                }
              },
              [
                _c(
                  "v-img",
                  {
                    attrs: {
                      src:
                        "https://static.mmmint.ai/wirkaufendeinethg/header-image.png",
                      gradient: "to top, rgba(0,0,0,.44), rgba(0,0,0,.44)",
                      height: "250"
                    }
                  },
                  [
                    _c(
                      "v-row",
                      {
                        staticClass: "ma-8",
                        attrs: {
                          justify: "center"
                        }
                      },
                      [
                        _c("vue-typer", {
                          staticClass:
                            "text-md-h2 text-sm-h3 text-h4 font-weight-medium mt-sm-8 mt-md-0 pt-8",
                          attrs: {
                            color: "white",
                            text: _vm.workText
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                attrs: {
                  align: "center",
                  justify: "center"
                }
              },
              [
                _c(
                  "v-card",
                  {
                    staticClass: "mr-8",
                    attrs: {
                      flat: "",
                      "max-width": "1100px"
                    }
                  },
                  [
                    _c(
                      "v-timeline",
                      {
                        attrs: {
                          dense: _vm.$vuetify.breakpoint.xsOnly
                        }
                      },
                      _vm._l(_vm.items, function(item) {
                        return _c(
                          "v-timeline-item",
                          {
                            key: item.id,
                            attrs: {
                              color: "primary",
                              small: "",
                              "fill-dot": ""
                            }
                          },
                          [
                            _c(
                              "v-alert",
                              {
                                staticClass: "white--text",
                                attrs: {
                                  value: true,
                                  color: "primary",
                                  icon: item.icon
                                }
                              },
                              [_vm._v(" " + _vm._s(item.text) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-row",
              {
                staticClass: "ma-8",
                attrs: {
                  align: "center",
                  justify: "center"
                }
              },
              [
                _c(
                  "v-window",
                  {
                    attrs: {
                      "show-arrows": ""
                    },
                    model: {
                      value: _vm.window,
                      callback: function callback($$v) {
                        _vm.window = $$v
                      },
                      expression: "window"
                    }
                  },
                  [
                    _c(
                      "v-window-item",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-15 ml-15",
                            attrs: {
                              color: "primary",
                              "x-large": "",
                              loading: _vm.loading
                            },
                            on: {
                              click: _vm.askForWork
                            }
                          },
                          [_vm._v(" THGs bearbeiten ")]
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-window-item",
                      [
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-15 ml-15",
                            attrs: {
                              color: "primary",
                              "x-large": "",
                              loading: _vm.loading
                            },
                            on: {
                              click: _vm.askForWork
                            }
                          },
                          [_vm._v(" Zählerstände bearbeiten ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.isVehicleSelected && _vm.selectedUser && _vm.hasNextWorkItem
        ? _c("thg-quote-workitem-card", {
            attrs: {
              selectedThg: _vm.thgWorkitem,
              selectedUser: _vm.selectedUser
            }
          })
        : _vm.isMeterReadingSelected && _vm.selectedUser && _vm.hasNextWorkItem
        ? _c("thg-quote-meter-reading-work-item-card", {
            attrs: {
              selectedUser: _vm.selectedUser,
              selectedThg: _vm.thgWorkitem,
              loading: _vm.loading
            },
            on: {
              askForWork: _vm.askForWork,
              "update:loading": function updateLoading($event) {
                _vm.loading = $event
              }
            }
          })
        : _c(
            "v-container",
            [
              _c(
                "v-row",
                {
                  staticClass: "ma-8",
                  attrs: {
                    align: "center",
                    justify: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        align: "center",
                        justify: "center"
                      }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          align: "center",
                          justify: "center",
                          contain: "",
                          "max-width": "700px",
                          src: require("@/assets/undraw/beer.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-8",
                  attrs: {
                    align: "center",
                    justify: "center"
                  }
                },
                [
                  _c("vue-typer", {
                    staticClass:
                      "text-md-h2 text-sm-h3 text-h4 font-weight-medium mt-sm-8 mt-md-0",
                    attrs: {
                      text: _vm.noMoreWorkText
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                {
                  staticClass: "ma-8",
                  attrs: {
                    align: "center",
                    justify: "center"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading
                      },
                      on: {
                        click: _vm.askForWork
                      }
                    },
                    [_vm._v("Refresh")]
                  )
                ],
                1
              )
            ],
            1
          ),
      !_vm.isWorkStarted || !_vm.hasNextWorkItem
        ? _c("filter-card-pagination", {
            attrs: {
              filterList: _vm.workitemFilterList,
              filter: _vm.workitemFilter,
              isSerachActivated: false
            },
            on: {
              "update:filter": function updateFilter($event) {
                _vm.workitemFilter = $event
              }
            }
          })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    [
      _c(
        "v-btn",
        {
          attrs: {
            disabled: _vm.disbledNew,
            elevation: "0",
            color: "info"
          },
          on: {
            click: _vm.openDialog
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("views.ThgBillingRecreationIndividualDialog.btn")) +
              " "
          )
        ]
      ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialog,
            width: 600,
            fullscreen: _vm.isMobile,
            title: _vm.$t("views.ThgBillingRecreationIndividualDialog.title"),
            rightLoading: _vm.loading,
            leftDisabled: _vm.loading,
            rightDisabled: _vm.disabled,
            rightText: _vm.$t(
              "views.ThgBillingRecreationIndividualDialog.btnTextAction"
            )
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            leftClick: function leftClick($event) {
              _vm.dialog = false
            },
            rightClick: _vm.confirmBillingRecreation
          }
        },
        [
          _c("v-text-field", {
            attrs: {
              disabled: "",
              label: _vm.$t(
                "views.ThgBillingRecreationIndividualDialog.controlText"
              ),
              value: _vm.thgs.length,
              outlined: "",
              "persistent-hint": ""
            }
          }),
          _vm.billingType === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION
            ? _c(
                "div",
                [
                  _c("v-text-field", {
                    attrs: {
                      type: "number",
                      label: "€/kWh"
                    },
                    model: {
                      value: _vm.pricePerKwh,
                      callback: function callback($$v) {
                        _vm.pricePerKwh = $$v
                      },
                      expression: "pricePerKwh"
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "v-btn",
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        loading: _vm.isLoading,
                        icon: ""
                      },
                      on: {
                        click: _vm.initialize
                      }
                    },
                    "v-btn",
                    attrs,
                    false
                  ),
                  on
                ),
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      }
                    },
                    [_vm._v("mdi-plus")]
                  )
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function callback($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      !_vm.isLoading
        ? _c(
            "v-card",
            [
              _c(
                "v-card-title",
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t(_vm.title)))]),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        icon: ""
                      }
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: "grey lighten-1"
                          },
                          on: {
                            click: _vm.close
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function callback($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-select", {
                        attrs: {
                          rules: _vm.requiredRules,
                          items: _vm.affiliateTypeEnum,
                          type: "text",
                          label: _vm.$t(
                            "components.thg.AffiliateCreateDialog.type"
                          ),
                          outlined: "",
                          required: ""
                        },
                        model: {
                          value: _vm.affiliateType,
                          callback: function callback($$v) {
                            _vm.affiliateType = $$v
                          },
                          expression: "affiliateType"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.requiredRules,
                          type: "string",
                          label: _vm.$t(
                            "components.thg.AffiliateCreateDialog.code"
                          ),
                          outlined: ""
                        },
                        model: {
                          value: _vm.createAffiliate.code,
                          callback: function callback($$v) {
                            _vm.$set(_vm.createAffiliate, "code", $$v)
                          },
                          expression: "createAffiliate.code"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          type: "string",
                          label: _vm.$t(
                            "components.thg.AffiliateCreateDialog.note"
                          ),
                          outlined: ""
                        },
                        model: {
                          value: _vm.createAffiliate.note,
                          callback: function callback($$v) {
                            _vm.$set(_vm.createAffiliate, "note", $$v)
                          },
                          expression: "createAffiliate.note"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.impactFactorRules,
                          type: "number",
                          label: _vm.$t(
                            "components.thg.AffiliateCreateDialog.discount"
                          ),
                          outlined: ""
                        },
                        model: {
                          value: _vm.createAffiliate.discountForCustomer,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.createAffiliate,
                              "discountForCustomer",
                              _vm._n($$v)
                            )
                          },
                          expression: "createAffiliate.discountForCustomer"
                        }
                      }),
                      _c("v-text-field", {
                        attrs: {
                          rules: _vm.impactFactorRules,
                          type: "number",
                          label: _vm.$t(
                            "components.thg.AffiliateCreateDialog.provision"
                          ),
                          outlined: ""
                        },
                        model: {
                          value: _vm.createAffiliate.provision,
                          callback: function callback($$v) {
                            _vm.$set(
                              _vm.createAffiliate,
                              "provision",
                              _vm._n($$v)
                            )
                          },
                          expression: "createAffiliate.provision"
                        }
                      }),
                      _c("v-card-title", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.AffiliateCreateDialog.chooseUser"
                            )
                          )
                        )
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.AffiliateCreateDialog.chooseUserDescription"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("v-switch", {
                        on: {
                          change: _vm.loadUsers
                        },
                        model: {
                          value: _vm.isUser,
                          callback: function callback($$v) {
                            _vm.isUser = $$v
                          },
                          expression: "isUser"
                        }
                      }),
                      _vm.isUser
                        ? _c("v-autocomplete", {
                            attrs: {
                              loading: _vm.isLoadingUsers,
                              rules: _vm.requiredRules,
                              items: _vm.users,
                              "item-value": "_id",
                              "item-text": "userName",
                              label: _vm.$t(
                                "components.thg.AffiliateCreateDialog.user"
                              ),
                              placeholder: "Start typing to Search",
                              outlined: ""
                            },
                            model: {
                              value: _vm.userId,
                              callback: function callback($$v) {
                                _vm.userId = $$v
                              },
                              expression: "userId"
                            }
                          })
                        : _vm._e(),
                      _c("v-card-title", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "components.thg.AffiliateCreateDialog.validTitle"
                            )
                          )
                        )
                      ]),
                      _c("v-card-text", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.AffiliateCreateDialog.validDescription"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("v-switch", {
                        attrs: {
                          label: _vm.isActivatedText
                        },
                        model: {
                          value: _vm.createAffiliate.isValid,
                          callback: function callback($$v) {
                            _vm.$set(_vm.createAffiliate, "isValid", $$v)
                          },
                          expression: "createAffiliate.isValid"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mx-4 mb-4",
                          attrs: {
                            disabled: !_vm.valid
                          },
                          on: {
                            click: _vm.update
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.$t(_vm.btnText)) + " ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "v-card",
        [
          _vm.isLoading
            ? _c("v-skeleton-loader", {
                attrs: {
                  type: "article, article, actions"
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
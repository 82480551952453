var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      directives: [
        {
          name: "resize",
          rawName: "v-resize",
          value: _vm.resize,
          expression: "resize"
        }
      ]
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            small: "",
            elevation: "0",
            color: "info"
          },
          on: {
            click: function click($event) {
              _vm.isEditDialogActive = true
            }
          }
        },
        [_vm._v("Zuschneiden")]
      ),
      _vm.isEditDialogActive
        ? _c(
            "v-dialog",
            {
              staticStyle: {
                width: "90%"
              },
              attrs: {
                fullscreen: ""
              },
              model: {
                value: _vm.isEditDialogActive,
                callback: function callback($$v) {
                  _vm.isEditDialogActive = $$v
                },
                expression: "isEditDialogActive"
              }
            },
            [
              _c(
                "card",
                {
                  attrs: {
                    hideTitle: true
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        height: _vm.height
                      }
                    },
                    [
                      _vm.thgsCopy.length > 1
                        ? _c(
                            "v-col",
                            {
                              attrs: {
                                cols: "3"
                              }
                            },
                            [
                              _c(
                                "v-card-title",
                                [
                                  _vm._v(" MultyClippy "),
                                  _c("v-spacer"),
                                  _c(
                                    "h4",
                                    {
                                      staticClass: "text-truncate"
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.currentThgIndex + 1) +
                                          "/" +
                                          _vm._s(_vm.filteredThgsCopy.length)
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c("v-card-subtitle", [
                                _vm.noFrontCount
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "components.thg.ThgQuoteWorkitemImageCard.noFront"
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  small: "",
                                                  outlined: !_vm.isNoFrontFilter,
                                                  color: "red"
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    _vm.isNoFrontFilter = !_vm.isNoFrontFilter
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.noFrontCount) +
                                                    " x " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.thg.ThgQuoteWorkitemImageCard.noFrontShort"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      small: ""
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "mdi-hand-front-right"
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noImageCount
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "components.thg.ThgQuoteWorkitemImageCard.noImg"
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  small: "",
                                                  outlined: !_vm.isNoImageFilter,
                                                  color: "red"
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    _vm.isNoImageFilter = !_vm.isNoImageFilter
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.noImageCount) +
                                                    " x " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.thg.ThgQuoteWorkitemImageCard.noImgShort"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      small: ""
                                                    }
                                                  },
                                                  [_vm._v("mdi-alert")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm.noCropCount
                                  ? _c(
                                      "span",
                                      [
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t(
                                                "components.thg.ThgQuoteWorkitemImageCard.noCrop"
                                              )
                                            }
                                          },
                                          [
                                            _c(
                                              "v-chip",
                                              {
                                                staticClass: "mx-1",
                                                attrs: {
                                                  small: "",
                                                  outlined: !_vm.isNoCropFilter,
                                                  color: "red"
                                                },
                                                on: {
                                                  click: function click(
                                                    $event
                                                  ) {
                                                    _vm.isNoCropFilter = !_vm.isNoCropFilter
                                                  }
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(_vm.noCropCount) +
                                                    " x " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "components.thg.ThgQuoteWorkitemImageCard.noCropShort"
                                                      )
                                                    ) +
                                                    " "
                                                ),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    staticClass: "ml-1",
                                                    attrs: {
                                                      small: ""
                                                    }
                                                  },
                                                  [_vm._v("mdi-crop ")]
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ]),
                              _c("v-divider"),
                              _c("v-virtual-scroll", {
                                staticClass: "ma-auto",
                                attrs: {
                                  height: _vm.height - 140,
                                  "item-height": "48",
                                  items: _vm.filteredThgsCopy
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "default",
                                      fn: function fn(_ref) {
                                        var item = _ref.item
                                        return [
                                          _c(
                                            "v-list-item",
                                            {
                                              key: item.id,
                                              attrs: {
                                                disabled: _vm.loading
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.selectThg(item)
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-list-item-content",
                                                [
                                                  !_vm.selectedThgId === item.id
                                                    ? _c("v-list-item-title", [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.numberplate ||
                                                                item
                                                                  .registration
                                                                  .numberplate
                                                            ) +
                                                            " (" +
                                                            _vm._s(item.year) +
                                                            ")" +
                                                            _vm._s(
                                                              _vm.selectedThgId
                                                            ) +
                                                            " " +
                                                            _vm._s(
                                                              _vm.selectedThgId ===
                                                                item.id
                                                            ) +
                                                            " "
                                                        )
                                                      ])
                                                    : _c("v-list-item-title", [
                                                        _c("strong", [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                item.numberplate ||
                                                                  item
                                                                    .registration
                                                                    .numberplate
                                                              ) +
                                                              " (" +
                                                              _vm._s(
                                                                item.year
                                                              ) +
                                                              ") "
                                                          )
                                                        ])
                                                      ])
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  !_vm.thgCroppingIsCompleteCheck.hasRegistrationFront(
                                                    item
                                                  )
                                                    ? _c(
                                                        "v-tooltip",
                                                        {
                                                          attrs: {
                                                            top: "",
                                                            "nudge-right": ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key:
                                                                  "activator",
                                                                fn: function fn(
                                                                  _ref2
                                                                ) {
                                                                  var on =
                                                                    _ref2.on
                                                                  return [
                                                                    _c(
                                                                      "v-icon",
                                                                      _vm._g(
                                                                        {
                                                                          attrs: {
                                                                            color:
                                                                              "red"
                                                                          }
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _vm._v(
                                                                          " mdi-hand-front-right "
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            true
                                                          )
                                                        },
                                                        [
                                                          _c("span", [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.$t(
                                                                  "components.thg.ThgQuoteWorkitemImageCard.noFront"
                                                                )
                                                              )
                                                            )
                                                          ])
                                                        ]
                                                      )
                                                    : _vm._e()
                                                ],
                                                1
                                              ),
                                              _c(
                                                "v-list-item-action",
                                                [
                                                  _vm.selectedThgId === item.id
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "black"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.loading
                                                                  ? "mdi-spin mdi-refresh"
                                                                  : "mdi-lead-pencil"
                                                              ) +
                                                              " "
                                                          )
                                                        ]
                                                      )
                                                    : _vm.thgCroppingIsCompleteCheck.getHasNoImages(
                                                        item
                                                      )
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "red"
                                                          }
                                                        },
                                                        [_vm._v(" mdi-alert ")]
                                                      )
                                                    : _vm.thgCroppingIsCompleteCheck.getHasAllImageEdits(
                                                        item
                                                      )
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color: "green"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-sticker-emoji "
                                                          )
                                                        ]
                                                      )
                                                    : _vm.thgCroppingIsCompleteCheck.getHasImageEdits(
                                                        item
                                                      )
                                                    ? _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "grey lighten-1"
                                                          }
                                                        },
                                                        [
                                                          _vm._v(
                                                            " mdi-sticker-check-outline "
                                                          )
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-icon",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "red lighten-2"
                                                          }
                                                        },
                                                        [_vm._v(" mdi-crop ")]
                                                      )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c("v-divider")
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  3297565914
                                )
                              }),
                              _c("v-divider"),
                              _c(
                                "v-row",
                                {
                                  staticClass: "mt-1"
                                },
                                [
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.leftButtonDisabled || _vm.loading
                                      },
                                      on: {
                                        click: _vm.previousThg
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                                    1
                                  ),
                                  _c("v-spacer"),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        icon: "",
                                        disabled:
                                          _vm.rightButtonDisabled || _vm.loading
                                      },
                                      on: {
                                        click: _vm.nextThg
                                      }
                                    },
                                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                                    1
                                  ),
                                  _c("v-spacer")
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider", {
                        attrs: {
                          vertical: ""
                        }
                      }),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols: _vm.thgsCopy.length > 1 ? 9 : 12
                          }
                        },
                        [
                          _c(
                            "card",
                            {
                              attrs: {
                                title: _vm.title,
                                subtitle: _vm.subtitle,
                                margin: 0,
                                outlined: false
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function fn() {
                                      return [
                                        _c("v-spacer"),
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: ""
                                            },
                                            on: {
                                              click: function click($event) {
                                                _vm.isEditDialogActive = false
                                              }
                                            }
                                          },
                                          [
                                            _c("v-icon", [
                                              _vm._v(" mdi-close ")
                                            ])
                                          ],
                                          1
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                false,
                                1581660692
                              )
                            },
                            [
                              !_vm.loading
                                ? _c(
                                    "div",
                                    {
                                      style: "height:".concat(
                                        _vm.height - 140,
                                        "px; width:100%"
                                      )
                                    },
                                    [
                                      _vm.isCropActive
                                        ? _c(
                                            "div",
                                            {
                                              style: "height:".concat(
                                                _vm.height - 140,
                                                "px; width:100%"
                                              )
                                            },
                                            [
                                              _vm.selectedThg
                                                ? _c("thg-crop-image", {
                                                    ref: "thgCropImage",
                                                    on: {
                                                      clipped: _vm.onCrop
                                                    }
                                                  })
                                                : _vm._e(),
                                              _c(
                                                "v-row",
                                                [
                                                  _c("v-spacer"),
                                                  _c("thg-crop-image-actions", {
                                                    attrs: {
                                                      horizontal: true
                                                    },
                                                    on: {
                                                      unclip: _vm.unclip,
                                                      reclip: _vm.reclip,
                                                      clip: _vm.clip
                                                    }
                                                  }),
                                                  _c("v-spacer")
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                width: "100%",
                                                height: "100%"
                                              }
                                            },
                                            [
                                              _c("thg-cropped-image"),
                                              _c(
                                                "v-row",
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "ml-5"
                                                    },
                                                    [
                                                      _c(
                                                        "context-menu",
                                                        {
                                                          attrs: {
                                                            icon:
                                                              "mdi-alert-octagram-outline",
                                                            color: "red"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "thg-work-item-rejection-card",
                                                            {
                                                              attrs: {
                                                                reduced: true,
                                                                selectedUser:
                                                                  _vm.selectedUser,
                                                                selectedThg:
                                                                  _vm.selectedThg,
                                                                userNotification:
                                                                  _vm.userNotification,
                                                                newWorkitem:
                                                                  _vm.newWorkitem
                                                              },
                                                              on: {
                                                                "update:userNotification": function updateUserNotification(
                                                                  $event
                                                                ) {
                                                                  _vm.userNotification = $event
                                                                },
                                                                "update:user-notification": function updateUserNotification(
                                                                  $event
                                                                ) {
                                                                  _vm.userNotification = $event
                                                                },
                                                                "update:newWorkitem": function updateNewWorkitem(
                                                                  $event
                                                                ) {
                                                                  _vm.newWorkitem = $event
                                                                },
                                                                "update:new-workitem": function updateNewWorkitem(
                                                                  $event
                                                                ) {
                                                                  _vm.newWorkitem = $event
                                                                }
                                                              }
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "thg-cropped-image-styling-options",
                                                    {
                                                      on: {
                                                        rotation:
                                                          _vm.debounceOnRotate
                                                      }
                                                    }
                                                  ),
                                                  _c("v-spacer"),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "mr-7",
                                                      staticStyle: {
                                                        width: "200px"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        staticClass: "mb-n6",
                                                        attrs: {
                                                          items:
                                                            _vm.possibleLabels,
                                                          outlined: "",
                                                          disabled: _vm.loading
                                                        },
                                                        on: {
                                                          change: _vm.onCrop
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "selection",
                                                              fn: function fn(
                                                                _ref3
                                                              ) {
                                                                var item =
                                                                  _ref3.item
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.label(
                                                                        item
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            },
                                                            {
                                                              key: "item",
                                                              fn: function fn(
                                                                _ref4
                                                              ) {
                                                                var item =
                                                                  _ref4.item
                                                                return [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.label(
                                                                        item
                                                                      )
                                                                    )
                                                                  )
                                                                ]
                                                              }
                                                            }
                                                          ],
                                                          null,
                                                          false,
                                                          984814620
                                                        ),
                                                        model: {
                                                          value:
                                                            _vm.currentLabel,
                                                          callback: function callback(
                                                            $$v
                                                          ) {
                                                            _vm.currentLabel = $$v
                                                          },
                                                          expression:
                                                            "currentLabel"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                    ]
                                  )
                                : _c(
                                    "div",
                                    {
                                      style: "height:".concat(
                                        _vm.height - 140,
                                        "px"
                                      )
                                    },
                                    [
                                      _c("v-skeleton-loader", {
                                        staticClass: "mx-auto",
                                        attrs: {
                                          type:
                                            "card-heading, divider, list-item-two-line,list-item-two-line, image"
                                        }
                                      })
                                    ],
                                    1
                                  )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
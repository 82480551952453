var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "card",
    {
      staticClass: "ma-2",
      attrs: {
        flat: "",
        outlined: "",
        "min-width": "250px",
        title: _vm.$t("components.partner.PartnerReportDetailImageCard.title")
      },
      scopedSlots: _vm._u([
        {
          key: "actions",
          fn: function fn() {
            return [
              _c("thg-edit-images-dialog", {
                attrs: {
                  thgs: [_vm.thg]
                }
              }),
              _c("thg-quote-update-registration-image-dialog"),
              _c("file-download", {
                attrs: {
                  downloadFiles: _vm.downloadableFiles
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-card",
        {
          staticClass: "mt-6",
          attrs: {
            flat: ""
          }
        },
        [
          _c("vue-picture-swipe", {
            attrs: {
              items: _vm.mapToImage,
              options: {
                shareEl: false,
                rotationOn: true
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
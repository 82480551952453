var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("paginated-table", {
        ref: "paginationTable",
        attrs: {
          store: _vm.pagination,
          title: _vm.$t("components.thg.thgBillingCsvSelection.title"),
          headers: _vm.headers,
          baseQuery: {},
          selectedItems: _vm.selected,
          showSelect: true
        },
        on: {
          "update:selectedItems": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "update:selected-items": function updateSelectedItems($event) {
            _vm.selected = $event
          },
          "click:row": _vm.onExpand
        },
        scopedSlots: _vm._u([
          {
            key: "bodyActions",
            fn: function fn() {
              return [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      color: "success",
                      disabled: _vm.selected.length === 0,
                      text: "",
                      outlined: ""
                    },
                    on: {
                      click: function click($event) {
                        _vm.publishDialog = true
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingCsvSelection.publish"
                          )
                        ) +
                        " "
                    )
                  ]
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "success",
                      disabled: _vm.selected.length === 0,
                      text: "",
                      outlined: ""
                    },
                    on: {
                      click: _vm.startCsvGeneration
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.thg.thgBillingCsvSelection.startCsvGeneration"
                          )
                        ) +
                        " "
                    )
                  ]
                )
              ]
            },
            proxy: true
          },
          {
            key: "item.accountRecordTotal",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      item.accountRecordTotal.toLocaleString("de-DE", {
                        style: "currency",
                        currency: "EUR"
                      })
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.relatedParties",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                item.partnerId
                  ? _c("small", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingCsvSelection.partnerId"
                            )
                          ) +
                          ": "
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function click($event) {
                              return _vm.goToPartnerDetail(item.partnerId)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.partnerId) + " ")]
                      ),
                      _c("br")
                    ])
                  : _vm._e(),
                item.userId
                  ? _c("small", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingCsvSelection.userId"
                            )
                          ) +
                          ": "
                      ),
                      _c(
                        "a",
                        {
                          on: {
                            click: function click($event) {
                              return _vm.goToUserDetail(item.userId)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(item.userId) + " ")]
                      ),
                      _c("br")
                    ])
                  : _vm._e()
              ]
            }
          },
          {
            key: "item.type",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.thgBillingCreateNewBillingManual." +
                          item.type
                      )
                    ) +
                    " "
                )
              ]
            }
          },
          {
            key: "item.timestamp.created",
            fn: function fn(_ref4) {
              var item = _ref4.item
              return [
                _c("span", [
                  _vm._v(
                    _vm._s(new Date(item.timestamp.created).toLocaleString())
                  )
                ])
              ]
            }
          },
          {
            key: "item.billingNumber",
            fn: function fn(_ref5) {
              var item = _ref5.item
              return [
                _c(
                  "a",
                  {
                    on: {
                      click: function click($event) {
                        return _vm.goToBilling(item.billingNumber)
                      }
                    }
                  },
                  [_vm._v(_vm._s(item.billingNumber))]
                )
              ]
            }
          },
          {
            key: "item.thgId",
            fn: function fn(_ref6) {
              var item = _ref6.item
              return [
                item.type === _vm.BillingTypeEnum.CREDIT_CHARGING_STATION
                  ? _c(
                      "a",
                      {
                        on: {
                          click: function click($event) {
                            return _vm.goToMeterReadingDetail(
                              item.partnerId,
                              item.thgId
                            )
                          }
                        }
                      },
                      [_c("small", [_vm._v(" " + _vm._s(item.thgId) + " ")])]
                    )
                  : _c(
                      "a",
                      {
                        on: {
                          click: function click($event) {
                            return _vm.goToThgDetail(item.partnerId, item.thgId)
                          }
                        }
                      },
                      [_c("small", [_vm._v(" " + _vm._s(item.thgId) + " ")])]
                    ),
                _vm._l(item.referenceIds || [], function(referenceId, index) {
                  return _c(
                    "div",
                    {
                      key: referenceId + item.number
                    },
                    [
                      index < 4
                        ? _c("div", [
                            item.type ===
                              _vm.BillingTypeEnum.CREDIT_CHARGING_STATION &&
                            index < 3
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function click($event) {
                                        return _vm.goToMeterReadingDetail(
                                          item.partnerId,
                                          referenceId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("small", [
                                      _vm._v(" " + _vm._s(referenceId) + " ")
                                    ])
                                  ]
                                )
                              : index < 3
                              ? _c(
                                  "a",
                                  {
                                    on: {
                                      click: function click($event) {
                                        return _vm.goToThgDetail(
                                          item.partnerId,
                                          referenceId
                                        )
                                      }
                                    }
                                  },
                                  [
                                    _c("small", [
                                      _vm._v(" " + _vm._s(referenceId) + " ")
                                    ])
                                  ]
                                )
                              : index === 3
                              ? _c("small", [
                                  _vm._v(
                                    " +" +
                                      _vm._s(item.referenceIds.length - 3) +
                                      " "
                                  )
                                ])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  )
                })
              ]
            }
          },
          {
            key: "item.isPublished",
            fn: function fn(_ref7) {
              var item = _ref7.item
              return [
                item.isPublished
                  ? _c(
                      "v-icon",
                      {
                        attrs: {
                          color: "success"
                        }
                      },
                      [_vm._v(" mdi-check ")]
                    )
                  : _c(
                      "v-icon",
                      {
                        attrs: {
                          color: "red"
                        }
                      },
                      [_vm._v(" mdi-close ")]
                    )
              ]
            }
          }
        ])
      }),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600"
          },
          on: {
            keydown: function keydown($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              return _vm.isPublish.apply(null, arguments)
            }
          },
          model: {
            value: _vm.publishDialog,
            callback: function callback($$v) {
              _vm.publishDialog = $$v
            },
            expression: "publishDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.publishDialog"
                      )
                    ) +
                    " "
                )
              ]),
              _c(
                "div",
                {
                  staticClass: "ml-7 mr-7"
                },
                [
                  _c("v-switch", {
                    attrs: {
                      label: ""
                        .concat(
                          _vm.$t(
                            "components.thg.thgBillingSelectionItemBillingResult.setPublish"
                          ),
                          ": "
                        )
                        .concat(_vm.isPublish.toString())
                    },
                    model: {
                      value: _vm.isPublish,
                      callback: function callback($$v) {
                        _vm.isPublish = $$v
                      },
                      expression: "isPublish"
                    }
                  }),
                  _vm.isPublish
                    ? _c("v-switch", {
                        attrs: {
                          label: ""
                            .concat(
                              _vm.$t(
                                "components.thg.thgBillingSelectionItemBillingResult.setNotifyUsers"
                              ),
                              ": "
                            )
                            .concat(_vm.isNotifyUsers.toString())
                        },
                        model: {
                          value: _vm.isNotifyUsers,
                          callback: function callback($$v) {
                            _vm.isNotifyUsers = $$v
                          },
                          expression: "isNotifyUsers"
                        }
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: function click($event) {
                          _vm.publishDialog = false
                        }
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemBillingResult.abort"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: _vm.openPublishConfirmationDialog
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemBillingResult.publish"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: {
            "max-width": "600"
          },
          model: {
            value: _vm.publishConfirmationDialog,
            callback: function callback($$v) {
              _vm.publishConfirmationDialog = $$v
            },
            expression: "publishConfirmationDialog"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.thg.thgBillingSelectionItemBillingResult.confirmDialogTitle"
                      )
                    ) +
                    " "
                )
              ]),
              _c("v-card-text", [
                _vm._v(" " + _vm._s(_vm.confirmationDialogText) + " ")
              ]),
              _c(
                "v-card-actions",
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: _vm.closePublishConfirmationDialog
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemBillingResult.abort"
                            )
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "primary",
                        text: ""
                      },
                      on: {
                        click: _vm.publishSelected
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "components.thg.thgBillingSelectionItemBillingResult.publish"
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.isExpandDialogActive && _vm.selectedForExpansion
        ? _c("thg-billing-side-card", {
            on: {
              close: function close($event) {
                _vm.isExpandDialogActive = false
              }
            },
            model: {
              value: _vm.selectedForExpansion,
              callback: function callback($$v) {
                _vm.selectedForExpansion = $$v
              },
              expression: "selectedForExpansion"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        width: "100%"
      }
    },
    [
      _c(
        "v-card-actions",
        {
          staticClass: "mt-n6 mb-n6"
        },
        [
          _c("v-checkbox", {
            attrs: {
              label: "Usernotification"
            },
            model: {
              value: _vm._userNotification,
              callback: function callback($$v) {
                _vm._userNotification = $$v
              },
              expression: "_userNotification"
            }
          }),
          !_vm.reduced
            ? _c("v-checkbox", {
                attrs: {
                  label: "New Workitem"
                },
                model: {
                  value: _vm._newWorkitem,
                  callback: function callback($$v) {
                    _vm._newWorkitem = $$v
                  },
                  expression: "_newWorkitem"
                }
              })
            : _vm._e(),
          _c("v-checkbox", {
            attrs: {
              label: "Clippy"
            },
            model: {
              value: _vm.isClippyAutoOpen,
              callback: function callback($$v) {
                _vm.isClippyAutoOpen = $$v
              },
              expression: "isClippyAutoOpen"
            }
          })
        ],
        1
      ),
      _c("v-divider"),
      !_vm.reduced
        ? _c("v-switch", {
            staticClass: "mb-4",
            attrs: {
              color: "red",
              "hide-details": "",
              label: "Fahrzeugschein ablehnen"
            },
            model: {
              value: _vm.isActionEnabled,
              callback: function callback($$v) {
                _vm.isActionEnabled = $$v
              },
              expression: "isActionEnabled"
            }
          })
        : _c("v-switch", {
            staticClass: "mb-4",
            attrs: {
              color: "red",
              "hide-details": "",
              label: "Kein Fahrzeugschein"
            },
            model: {
              value: _vm.disabled,
              callback: function callback($$v) {
                _vm.disabled = $$v
              },
              expression: "disabled"
            }
          }),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref) {
                      var on = _ref.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "warning"
                              },
                              on: {
                                click: _vm.requestRegistrationImage
                              }
                            },
                            on
                          ),
                          [_vm._v(" Fahrzeugschein nicht lesbar ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3989715950
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn:"),
                _c("br"),
                _vm._v(" - der Fahrzeugschein nicht lesbar ist"),
                _c("br"),
                _vm._v(" - die Auflösung extrem schlecht"),
                _c("br"),
                _vm._v(" - die Vorderseite nicht unter den Fotos ist"),
                _c("br"),
                _c("br"),
                _vm._v(" Status: REQUEST_REGISTRATION_IMAGES"),
                _c("br"),
                _vm._v(
                  " ...uns ist aufgefallen, dass uns leider keine Bilder von deinem Fahrzeugschein vorliegen oder die Fotos nicht lesbar sind... "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref2) {
                      var on = _ref2.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.noBev
                              }
                            },
                            on
                          ),
                          [_vm._v("Kein E-Fahrzeug")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                2497568195
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn es sich:"),
                _c("br"),
                _vm._v(" - um ein Hybridfahrzeug handelt"),
                _c("br"),
                _vm._v(" - um ein Dieselfahrzeug handelt"),
                _c("br"),
                _vm._v(" - um ein Benzinfahrzeug handelt"),
                _c("br"),
                _vm._v(" - um kein E-Fahrzeug handelt"),
                _c("br"),
                _c("br"),
                _vm._v(" Status: NO_BEV"),
                _c("br"),
                _vm._v(
                  " ...uns aufgefallen, dass es sich bei deinem Fahrzeug um kein rein elektrisches Fahrzeug handelt... "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref3) {
                      var on = _ref3.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.isSpam
                              }
                            },
                            on
                          ),
                          [_vm._v("Spam")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                262387064
              )
            },
            [
              _c("span", [
                _vm._v(" Status: SPAM"),
                _c("br"),
                _vm._v(" Keine Mail ")
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref4) {
                      var on = _ref4.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.isNoGermanRegistationDocument
                              }
                            },
                            on
                          ),
                          [_vm._v(" Keine Zulassungsbescheinigung ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                4224499913
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn es sich:"),
                _c("br"),
                _vm._v(" - nicht um einen Fahrzeugschein handelt"),
                _c("br"),
                _vm._v(" - um ein Versicherungsdokument handelt"),
                _c("br"),
                _vm._v(
                  " - einen Fahrzeugschein aus einem anderen Land handelt"
                ),
                _c("br"),
                _c("br"),
                _vm._v(" Status: NO_GERMAN_REGISTRATION_DOCUMENT"),
                _c("br"),
                _vm._v(
                  "...aufgefallen, dass du uns nicht die Zulassungsbescheinigung Teil I übermittelt hast... "
                )
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref5) {
                      var on = _ref5.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.cancelOrder
                              }
                            },
                            on
                          ),
                          [_vm._v(" Vom Vertrag zurücktreten ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                894316714
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn"),
                _c("br"),
                _vm._v(" - wir vom Verag zurücktreten wollen"),
                _c("br")
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref6) {
                      var on = _ref6.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.setInvalidVehicleClass
                              }
                            },
                            on
                          ),
                          [_vm._v(" Fahrzeugklasse nicht antragsberechtigt ")]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3775151141
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn"),
                _c("br"),
                _vm._v(
                  " - eine der folgenden Fahrzeugklassen vorliegt: L1e-A, L1e-B, L2e-P, L2e-U, L3e-A1, L3e (Leichtkraftrad B), L4e-A1, L6e-A, L6e-BP, L6e-BU, 24 und 25"
                ),
                _c("br")
              ])
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled && _vm.isAt
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref7) {
                      var on = _ref7.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.setHybridAt
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgStatusMap.HYBRID_AT.portalText"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3010297214
              )
            },
            [
              _c("span", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("components.thg.ThgStatusMap.HYBRID_AT.portalHint")
                  )
                }
              })
            ]
          )
        : _vm._e(),
      _vm.isActionEnabled
        ? _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function fn(_ref8) {
                      var on = _ref8.on
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "ma-1",
                              attrs: {
                                disabled: _vm.disabled,
                                block: "",
                                color: "error"
                              },
                              on: {
                                click: _vm.setPayedAndInvalidVehicleClass
                              }
                            },
                            on
                          ),
                          [
                            _vm._v(
                              " Bezahlt, aber nicht mehr antragsberechtigt "
                            )
                          ]
                        )
                      ]
                    }
                  }
                ],
                null,
                false,
                3168706418
              )
            },
            [
              _c("span", [
                _vm._v(" Nutze diesen Status, wenn"),
                _c("br"),
                _vm._v(" - THG-Quote bereits ausgezahlt wurde"),
                _c("br"),
                _vm._v(
                  " - eine der folgenden Fahrzeugklassen vorliegt: L1e-A, L1e-B, L2e-P, L2e-U, L3e-A1, L3e (Leichtkraftrad B), L4e-A1, L6e-A, L6e-BP, L6e-BU, 24 und 25"
                ),
                _c("br")
              ])
            ]
          )
        : _vm._e(),
      _c("template-dialog", {
        attrs: {
          context: _vm.context,
          from: _vm.from,
          to: _vm.to,
          large: true
        }
      }),
      _vm.isQuickCheckoutDialogActive
        ? _c("thg-quote-quick-checkout-list-dialog", {
            attrs: {
              handleInvalidClass: true,
              fullscreen: true,
              isDialogActive: _vm.isQuickCheckoutDialogActive,
              parentId: _vm.selectedThg.parentId,
              partnerId: _vm.selectedThg.partnerId
            },
            on: {
              "update:isDialogActive": function updateIsDialogActive($event) {
                _vm.isQuickCheckoutDialogActive = $event
              },
              "update:is-dialog-active": function updateIsDialogActive($event) {
                _vm.isQuickCheckoutDialogActive = $event
              },
              close: _vm.closeIsQuickCheckoutDialogActive
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
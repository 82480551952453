var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      ref: "quoteForm",
      model: {
        value: _vm.isValid,
        callback: function callback($$v) {
          _vm.isValid = $$v
        },
        expression: "isValid"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-card-text", [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.thg.ThgQuoteForm.customerInfo")) +
                  " "
              )
            ])
          ]),
          _c(
            "v-row",
            [
              _c("v-text-field", {
                staticClass: "px-3",
                attrs: {
                  rules: _vm.requiredRule,
                  label: _vm.$t("components.thg.ThgQuoteForm.firstName"),
                  required: "",
                  outlined: "",
                  "data-test-form-firstName": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "prepend",
                    fn: function fn() {
                      return [
                        _c(
                          "v-tooltip",
                          {
                            attrs: {
                              bottom: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "activator",
                                fn: function fn(_ref) {
                                  var on = _ref.on
                                  return [
                                    _c("v-icon", _vm._g({}, on), [
                                      _vm._v(" mdi-help-circle-outline ")
                                    ])
                                  ]
                                }
                              }
                            ])
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "components.thg.ThgQuoteForm.tooltip.firstName"
                                  )
                                ) +
                                " "
                            )
                          ]
                        )
                      ]
                    },
                    proxy: true
                  }
                ]),
                model: {
                  value: _vm.createUserDto.firstName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createUserDto, "firstName", $$v)
                  },
                  expression: "createUserDto.firstName"
                }
              }),
              _c("v-text-field", {
                staticClass: "px-3",
                attrs: {
                  rules: _vm.requiredRule,
                  label: _vm.$t("components.thg.ThgQuoteForm.lastName"),
                  required: "",
                  outlined: "",
                  "data-test-form-lastName": ""
                },
                model: {
                  value: _vm.createUserDto.lastName,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createUserDto, "lastName", $$v)
                  },
                  expression: "createUserDto.lastName"
                }
              })
            ],
            1
          ),
          _c("v-text-field", {
            attrs: {
              rules: _vm.emailRule,
              label: _vm.$t("components.thg.ThgQuoteForm.userName"),
              required: "",
              outlined: "",
              type: "email",
              "data-test-form-userName": ""
            },
            scopedSlots: _vm._u([
              {
                key: "prepend",
                fn: function fn() {
                  return [
                    _c(
                      "v-tooltip",
                      {
                        attrs: {
                          bottom: ""
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function fn(_ref2) {
                              var on = _ref2.on
                              return [
                                _c("v-icon", _vm._g({}, on), [
                                  _vm._v(" mdi-help-circle-outline ")
                                ])
                              ]
                            }
                          }
                        ])
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.thg.ThgQuoteForm.tooltip.userName"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  ]
                },
                proxy: true
              }
            ]),
            model: {
              value: _vm.createUserDto.userName,
              callback: function callback($$v) {
                _vm.$set(_vm.createUserDto, "userName", $$v)
              },
              expression: "createUserDto.userName"
            }
          }),
          _c(
            "v-btn",
            {
              attrs: {
                loading: _vm.loading,
                color: "success",
                disabled: !_vm.isValid,
                "data-test-form-submit": ""
              },
              on: {
                click: _vm.save
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("components.thg.ThgQuoteForm.submit")) + " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("csv-import-dialog", {
    attrs: {
      title: "components.utility.CsvImportDialog.title",
      headers: _vm.headers,
      create: _vm.create
    },
    on: {
      change: _vm.changeItems,
      refresh: _vm.refresh
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
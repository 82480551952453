var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "components.thg.ThgChargingStationCreateView.title"
      }
    },
    [
      _c(
        "v-stepper",
        {
          model: {
            value: _vm.stepper,
            callback: function callback($$v) {
              _vm.stepper = $$v
            },
            expression: "stepper"
          }
        },
        [
          _c(
            "v-stepper-header",
            [
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.isPartnerSet,
                    editable: "",
                    step: "1"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgChargingStationCreateView.partnerInfo"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.isUserSet,
                    editable: "",
                    step: "2"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgChargingStationCreateView.customerInfo"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.isChargingStationSet,
                    editable: "",
                    step: "3"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgChargingStationCreateView.chargingInfo"
                        )
                      ) +
                      " "
                  )
                ]
              ),
              _c("v-divider"),
              _c(
                "v-stepper-step",
                {
                  attrs: {
                    complete: _vm.isMeterReadingSet,
                    editable: "",
                    step: "4"
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgChargingStationCreateView.meterReadingInfo"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-stepper-items",
            [
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: "1"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c("partner-selection-form", {
                        attrs: {
                          loading: _vm.loading
                        },
                        on: {
                          save: _vm.savePartner
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: "2"
                  }
                },
                [
                  _c(
                    "v-container",
                    [
                      _c("thg-customer-data-form", {
                        attrs: {
                          loading: _vm.loading
                        },
                        on: {
                          save: _vm.saveUser
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: "3"
                  }
                },
                [
                  _c("thg-charging-station-form", {
                    attrs: {
                      loading: _vm.loading,
                      detail: ""
                    },
                    on: {
                      save: _vm.saveChargingStation
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: "4"
                  }
                },
                [
                  _c("thg-meter-reading-form", {
                    attrs: {
                      isSaving: _vm.loading
                    },
                    on: {
                      save: _vm.saveMeterReading
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
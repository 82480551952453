var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "report.thgChargingStation.meterReading.title"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "wrapper"
        },
        [
          _c("thg-meter-reading-detail-card", {
            attrs: {
              loading: _vm.loading,
              selectedUser: _vm.selectedUser
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        "max-width": "800",
        fullscreen: _vm.$vuetify.breakpoint.xsOnly,
        transition: "dialog-bottom-transition"
      },
      on: {
        "click:outside": _vm.close
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              !_vm.hideActivator
                ? _c(
                    "v-btn",
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            loading: _vm.loading,
                            icon: ""
                          },
                          on: {
                            click: _vm.openDialog
                          }
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_c("v-icon", [_vm._v("mdi-pencil")])],
                    1
                  )
                : _vm._e()
            ]
          }
        }
      ]),
      model: {
        value: _vm.isDialogActive,
        callback: function callback($$v) {
          _vm.isDialogActive = $$v
        },
        expression: "isDialogActive"
      }
    },
    [
      _c(
        "v-card",
        [
          _c(
            "v-card-title",
            [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "components.thg.ThgQuoteDetailInformationStatusCard.title"
                    )
                  )
                )
              ]),
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    icon: ""
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        color: "grey lighten-1"
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_vm._v("mdi-close")]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c("debug", [_vm._v(_vm._s(_vm.thg))]),
              _c(
                "v-form",
                {
                  ref: "form",
                  model: {
                    value: _vm.isValid,
                    callback: function callback($$v) {
                      _vm.isValid = $$v
                    },
                    expression: "isValid"
                  }
                },
                [
                  _c("v-autocomplete", {
                    attrs: {
                      items: _vm.items,
                      rules: _vm.requiredRule,
                      label: _vm.$t(
                        "components.thg.ThgQuoteDetailInformationStatusCard.status"
                      ),
                      hint: _vm.$t(
                        "components.thg.ThgQuoteDetailInformationStatusCard.statusHint"
                      ),
                      "persistent-hint": "",
                      required: "",
                      outlined: "",
                      solo: "",
                      "data-test-status": ""
                    },
                    model: {
                      value: _vm.status,
                      callback: function callback($$v) {
                        _vm.status = $$v
                      },
                      expression: "status"
                    }
                  }),
                  _c("v-checkbox", {
                    attrs: {
                      label: _vm.$t(
                        "components.thg.ThgQuoteDetailInformationStatusCard.userNotification"
                      ),
                      hint: _vm.$t(
                        "components.thg.ThgQuoteDetailInformationStatusCard.userNotificationHint"
                      ),
                      "persistent-hint": ""
                    },
                    model: {
                      value: _vm.userNotification,
                      callback: function callback($$v) {
                        _vm.userNotification = $$v
                      },
                      expression: "userNotification"
                    }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "v-expansion-panels",
                [
                  _vm._l(_vm.helpItems, function(item, i) {
                    return [
                      _c(
                        "v-expansion-panel",
                        {
                          key: i
                        },
                        [
                          _c(
                            "v-expansion-panel-header",
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function fn() {
                                      return [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              color: item.color
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.icon) + " "
                                            )
                                          ]
                                        )
                                      ]
                                    },
                                    proxy: true
                                  }
                                ],
                                null,
                                true
                              )
                            },
                            [_vm._v(" " + _vm._s(item.title) + " ")]
                          ),
                          _c("v-expansion-panel-content", [
                            _vm._v(" " + _vm._s(_vm.$t(item.content)) + " ")
                          ])
                        ],
                        1
                      )
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-4",
                  attrs: {
                    color: "success",
                    disabled: !_vm.isValid,
                    loading: _vm.loading
                  },
                  on: {
                    click: _vm.save
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.thg.ThgQuoteDetailInformationStatusCard.btnText"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("partner-selection-form", {
        attrs: {
          loading: _vm.loading,
          partnerId: _vm.partnerId
        },
        on: {
          save: _vm.savePartnerId
        }
      }),
      _c("v-text-field", {
        attrs: {
          rules: _vm.yearRules,
          outlined: "",
          label: _vm.$t("components.thg.EfahrerCreateThgForm.year")
        },
        on: {
          change: function change($event) {
            return _vm.$emit("yearChange", $event)
          }
        },
        model: {
          value: _vm.year,
          callback: function callback($$v) {
            _vm.year = $$v
          },
          expression: "year"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          label: _vm.$t("components.thg.EfahrerCreateThgForm.code")
        },
        on: {
          change: function change($event) {
            return _vm.$emit("codeChange", $event)
          }
        },
        model: {
          value: _vm.code,
          callback: function callback($$v) {
            _vm.code = $$v
          },
          expression: "code"
        }
      }),
      _c("v-text-field", {
        attrs: {
          outlined: "",
          label: _vm.$t("components.thg.EfahrerCreateThgForm.campaign")
        },
        on: {
          change: function change($event) {
            return _vm.$emit("campaignChange", $event)
          }
        },
        model: {
          value: _vm.campaign,
          callback: function callback($$v) {
            _vm.campaign = $$v
          },
          expression: "campaign"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("pie-chart-card", {
    attrs: {
      data: _vm.partnerDistributionData,
      title:
        "components.thg.ThgBatchDetailInformationPartnerDistributionCard.title"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
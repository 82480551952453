var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      attrs: {
        fullscreen: ""
      },
      model: {
        value: _vm.isDialogActive,
        callback: function callback($$v) {
          _vm.isDialogActive = $$v
        },
        expression: "isDialogActive"
      }
    },
    [
      _c(
        "card",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.resize,
              expression: "resize"
            }
          ],
          attrs: {
            margin: 0,
            hideTitle: true
          }
        },
        [
          _c(
            "v-btn",
            {
              staticStyle: {
                position: "absolute",
                right: "15px",
                top: "15px, z-index: 999"
              },
              attrs: {
                icon: ""
              },
              on: {
                click: function click($event) {
                  _vm.isDialogActive = false
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-close")])],
            1
          ),
          _c(
            "v-row",
            {
              style: "height:".concat(_vm.height, "px")
            },
            [
              _c(
                "v-col",
                {
                  staticStyle: {
                    height: "100%"
                  },
                  attrs: {
                    cols: "12",
                    md: "9"
                  }
                },
                [
                  _vm.isDialogActive
                    ? _c("thg-crop-image", {
                        ref: "thgCropImage"
                      })
                    : _vm._e()
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "mt-5",
                  attrs: {
                    cols: "12",
                    md: "3"
                  }
                },
                [
                  _c(
                    "table",
                    {
                      staticStyle: {
                        height: "100%"
                      }
                    },
                    [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            attrs: {
                              valign: "top"
                            }
                          },
                          [_vm._t("additionalActionsTop")],
                          2
                        )
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            attrs: {
                              valign: "middle"
                            }
                          },
                          [
                            _c("thg-crop-image-actions", {
                              staticClass: "pr-4",
                              on: {
                                unclip: _vm.unclip,
                                reclip: _vm.reclip,
                                clip: _vm.clip
                              }
                            })
                          ],
                          1
                        )
                      ]),
                      _c("tr", [
                        _c(
                          "td",
                          {
                            attrs: {
                              valign: "bottom"
                            }
                          },
                          [_vm._t("additionalActions")],
                          2
                        )
                      ])
                    ]
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }